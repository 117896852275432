import axios from "axios"
import { baseURL } from "./common-api"
import { IActivity } from "./task-api"

export interface ITaskReference {
    id_seq?: number,
    commodity_name_var?: string,
    planting_method_int?: string,
    name_planting_var?: string,
    file_name_txt?: string,
    file_size_int?: string,
    file?: any,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}


export interface ITaskReferenceDetail extends IActivity {
    file_name_txt: string,
}


export class TaskReferenceApi {
    static getAll = async () => {
        try {
            const data = await axios.get(baseURL + '/api/task-reference/get-all', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static getOne = async (idSeq: number) => {
        try {
            const data = await axios.get(baseURL + '/api/task-reference/get-one?id_seq=' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static createNew = async (payload: ITaskReference | FormData) => {
        try {
            const data = await axios.post(baseURL + '/api/task-reference/create', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static update = async (idSeq: number, payload: ITaskReference | FormData) => {
        try {
            const data = await axios.patch(baseURL + '/api/task-reference/update/' + idSeq, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static delete = async (idSeq: number) => {
        try {
            const data = await axios.delete(baseURL + '/api/task-reference/delete/' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static detail = async (fileName: string) => {
        try {
            const tableName = fileName.split('.').length > 0 ? fileName.split('.')[0] : fileName

            const data = await axios.post(baseURL + '/api/task-reference/detail', { file_name_txt: tableName }, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
}

export class TaskReferenceDetailApi {

    static getAll = async (fileName: string) => {
        try {
            const tableName = fileName.split('.').length > 0 ? fileName.split('.')[0] : fileName

            const data = await axios.get(baseURL + '/api/task-ref-detail/get-all?file_name_txt=' + tableName, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static getOne = async (idSeq: number) => {
        try {
            const data = await axios.get(baseURL + '/api/task-ref-detail/get-one?id_seq=' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static createNew = async (payload: ITaskReferenceDetail) => {
        try {
            const data = await axios.post(baseURL + '/api/task-ref-detail/create', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static update = async (idSeq: number, payload: ITaskReferenceDetail) => {
        try {
            const data = await axios.patch(baseURL + '/api/task-ref-detail/update/' + idSeq, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static delete = async (idSeq: number, fileName: string,) => {
        try {
            const tableName = fileName.split('.').length > 0 ? fileName.split('.')[0] : fileName

            const res = await fetch(baseURL + '/api/task-ref-detail/delete/' + idSeq, {
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ file_name_txt: tableName })
            })

            const data = await res.json()

            // const data = await axios.delete(baseURL + '/api/task-ref-detail/delete/' + idSeq, {file_name_txt: tableName}, {
                // headers: {
                //     authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                //     Accept: "application/json",
                //     "Content-Type": "application/json"
                // }
            // })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
}