import React, { useContext, useState } from 'react';
import Select from 'react-select'
import { toast } from 'react-toastify';
import TaskApi, { IObservartion } from '../../../api/task-api';
import { GlobalContext } from '../../../context/GlobalProvider';
import useDropdownPengamtan from '../../../hooks/useDropdownPengamatan';
import Loader from '../Loader';
import { selectStylesForm } from './ModalTaskNew';

type Props = {
    data?: IObservartion,
    daySelected?: string,
    onSubmit?: (data: IObservartion) => any,
    onCancel?: (e?: React.MouseEvent<HTMLElement>) => any
};

const CustomOptionLeafCondition = ({ leaf_condition_var, color_code_var }: any) => {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ backgroundColor: color_code_var }} className='rounded-full w-4 h-4 mr-3' />
            <div>{leaf_condition_var}</div>
        </div>
    )
}


const Pengamatan: React.FC<Props> = ({ onCancel, onSubmit, data, daySelected }) => {
    const [loader, showLoader] = useState(false)

    const { ownerTask, userStorage, setIsDataChanged } = useContext(GlobalContext)

    const {
        dataLeaf, loadingLeaf,
        dataLand, loadingLand,
        dataPuppies, loadingPuppies,
        dataWatering, loadingWatering,
        dataGrain, loadingGrain
    } = useDropdownPengamtan()

    const handleSubmitPengamatan = async (e: React.ChangeEvent<HTMLFormElement>) => {
        showLoader(true)
        e.preventDefault()

        const { leaf_condition_txt, land_condition_txt, watering_condition_txt, puppies_condition_txt, grain_condition_txt, hama_txt, example_observation_txt } = e.target

        const payload = {
            leaf_condition_txt: leaf_condition_txt.value,
            land_condition_txt: land_condition_txt.value,
            watering_condition_txt: watering_condition_txt.value,
            puppies_condition_txt: puppies_condition_txt.value,
            grain_condition_txt: grain_condition_txt.value,
            hama_txt: hama_txt.value,
            example_observation_txt: example_observation_txt.value,
            time_calender_dte: daySelected
            // jumlah_txt: jumlah_txt.value,
            // satuan_txt: satuan_txt.value
        } as IObservartion

        payload['user_id_int'] = ownerTask.user_id_int
        payload['land_code_var'] = ownerTask.land_code_var
        payload['period_plant_txt'] = ownerTask.period_plant_txt

        let res = null
        if (!data) {
            payload['created_by_var'] = userStorage.fullname_var
            res = await TaskApi.createObservation(payload)
        } else {
            payload['updated_by_var'] = userStorage.fullname_var
            res = await TaskApi.updateObservation(data.id_seq, payload)
        }

        console.log('Observation :', res)
        showLoader(false)
        if (res.data && res.data.code === 0) {
            toast.success(res.data.message)
            setIsDataChanged(true)
            // e.target.reset()
            if (onSubmit) onSubmit(payload)
            if (onCancel) onCancel()
            // handleResetFormValue()
        } else {
            toast.error(res.data?.message || res.message)
        }
    }

    return (
        <>
            {!loadingLand && !loadingLeaf && !loadingPuppies && !loadingPuppies && !loadingGrain ?
                <form className='mt-5 md:px-4' onSubmit={handleSubmitPengamatan} >
                    <div className='space-y-3'>
                        <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="leaf" className="label-form">Kondisi Daun</label>
                                <Select id='leaf' name='leaf_condition_txt' styles={selectStylesForm} defaultValue={dataLeaf.find(item => item['leaf_condition_var'].toLowerCase() === data?.leaf_condition_txt.toLowerCase())} options={dataLeaf} isClearable isLoading={loadingLeaf} placeholder='- Pilih Kondisi -'
                                    getOptionValue={(option: any) => option['leaf_condition_var']}
                                    getOptionLabel={(option: any) => option['leaf_condition_var']}
                                    formatOptionLabel={CustomOptionLeafCondition}
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor="land" className="label-form">Kondisi Tanah/Lahan</label>
                                <Select id='land' name='land_condition_txt' styles={selectStylesForm} defaultValue={dataLand.find(item => item['land_condition_var'].toLowerCase() === data?.land_condition_txt.toLowerCase())} options={dataLand} isClearable isLoading={loadingLand} placeholder='- Pilih Kondisi -'
                                    getOptionValue={(option: any) => option['land_condition_var']}
                                    getOptionLabel={(option: any) => option['land_condition_var']}
                                />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="watering" className="label-form">Kondisi Pengairan</label>
                                {/* <Select id='watering' name='watering_condition_var' styles={selectStylesForm} value={selectedWatering} onChange={setSelectedWatering} options={dataWatering} isClearable isLoading={loadingWatering} placeholder='- Pilih Kondisi -' */}
                                <Select id='watering' name='watering_condition_txt' styles={selectStylesForm} defaultValue={dataWatering.find(item => item['watering_condition_var'].toLowerCase() === data?.watering_condition_txt.toLowerCase())} options={dataWatering} isClearable isLoading={loadingWatering} placeholder='- Pilih Kondisi -'
                                    getOptionValue={(option: any) => option['watering_condition_var']}
                                    getOptionLabel={(option: any) => option['watering_condition_var']}
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor="puppies" className="label-form">Kondisi Anakan</label>
                                {/* <Select id='puppies' name='puppies_condition_var' styles={selectStylesForm} value={selectedPuppies} onChange={setSelectedPuppies} options={dataPuppies} isClearable isLoading={loadingPuppies} placeholder='- Pilih Kondisi -' */}
                                <Select id='puppies' name='puppies_condition_txt' styles={selectStylesForm} defaultValue={dataPuppies.find(item => item['puppies_condition_var'].toLowerCase() === data?.puppies_condition_txt.toLowerCase())} options={dataPuppies} isClearable isLoading={loadingPuppies} placeholder='- Pilih Kondisi -'
                                    getOptionValue={(option: any) => option['puppies_condition_var']}
                                    getOptionLabel={(option: any) => option['puppies_condition_var']}
                                />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="grain" className="label-form">Kondisi Bulir</label>
                                {/* <Select id='grain' name='grain_condition_var' styles={selectStylesForm} value={selectedGrain} onChange={setSelectedGrain} options={dataGrain} isClearable isLoading={loadingGrain} placeholder='- Pilih Kondisi -' */}
                                <Select id='grain' name='grain_condition_txt' styles={selectStylesForm} defaultValue={dataGrain.find(item => item['grain_condition_var'].toLowerCase() === data?.grain_condition_txt.toLowerCase())} options={dataGrain} isClearable isLoading={loadingGrain} placeholder='- Pilih Kondisi -'
                                    getOptionValue={(option: any) => option['grain_condition_var']}
                                    getOptionLabel={(option: any) => option['grain_condition_var']}
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor="hama_txt" className="label-form">Adakah Serangan Hama/Penyakit</label>
                                <select id="hama_txt" className="input-task" defaultValue={data?.hama_txt}>
                                    <option value=''>- Pilih Kondisi -</option>
                                    <option value='Ada'>Ada</option>
                                    <option value='Tidak Ada'>Tidak Ada</option>
                                </select>
                            </div>
                        </div>
                        <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="example_observation_txt" className="label-form">Catatan Pengamatan</label>
                                <textarea name='example_observation_txt' id='example_observation_txt' className='input-task' defaultValue={data?.example_observation_txt} />
                            </div>
                        </div>
                        {/* <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                        <div className="w-full">
                            <label htmlFor="jumlah" className="label-form">Jumlah HST</label>
                            <input type='number' name='jumlah_txt' id="jumlah" className="input-task" defaultValue={data?.jumlah_txt || ''} placeholder="18" />
                        </div>
                        <div className="w-full">
                            <label htmlFor="satuan" className="label-form">Satuan HST</label>
                            <input id="satuan" name='satuan_txt' className="input-task" defaultValue={data?.satuan_txt || ''} placeholder="ST" />
                        </div>
                    </div> */}
                    </div>
                    <footer className='flex justify-end mt-7 space-x-2'>
                        <button type='reset' className='text-gray-700 px-6 py-2.5 rounded-lg text-sm' onClick={onCancel}>Batal</button>
                        <button type='submit' className='bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm'>Simpan</button>
                    </footer>
                </form>
                :
                <Loader />
            }

            {loader && <Loader />}
        </>
    );
}

export default Pengamatan;