import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import Select from "react-select";
import dayjs from "dayjs";
import Loader from "../Loader";
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";

import { IBudgetCategory } from "../../../api/budgetplan-api";
import useDropdownCommodity from "../../../hooks/useDropdownCommodity";

type Props = {
  data: IBudgetCategory;
  onCancel: (e: React.MouseEvent) => any;
  onSubmit: (data: IBudgetCategory) => void;
};

const BPCategoryForm: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
  const [selectedCommodity, setSelectedCommodity] = useState<any>(null);

  const { dataCommodity, loadingCommodity } = useDropdownCommodity();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IBudgetCategory>({ criteriaMode: "all" });

  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key: any) => {
        if (key === "start_planting_dtm") {
          return setValue(key, dayjs(obj[key]).format("YYYY-MM-DD"));
        } else {
          return setValue(key, obj[key], { shouldValidate: true });
        }
      });
    },
    [setValue]
  );

  useEffect(() => {
    if (data) {
      setdefaultValue(data);
    }
  }, [data, setdefaultValue]);

  useEffect(() => {
    if (data) {
      setSelectedCommodity(dataCommodity.find((item) => item.commodity_name_var === data.commodity_name_var));
    }
    // eslint-disable-next-line
  }, [dataCommodity]);

  const onSubmitForm: SubmitHandler<IBudgetCategory> = ({ category_var, activity_txt, commodity_name_var }) => {
    const payload = {
      commodity_name_var: selectedCommodity.commodity_name_var,
      category_var,
      activity_txt,
    };

    console.log(payload);
    onSubmit(payload);
  };

  return (
    <>
      {!loadingCommodity ? (
        <div className="modal-form">
          <div className="modal-form-outside" onClick={onCancel}></div>

          <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
            <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
              <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                <h3>Budget Plan Category</h3>
              </div>
              <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white" onClick={onCancel}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
              <div className="grid gap-5 grid-cols-1 md:grid-cols-2">
                <div className="w-full">
                  <label htmlFor="category_var" className="label-form">
                    Kategori
                  </label>
                  <input className="input-form" id="category_var" {...register("category_var", { required: "This field is required" })} placeholder="Kategori Budget Plan" />
                  <ErrorField errors={errors} name="category_var" />
                </div>
                <div className="w-full">
                  <label htmlFor="activity_txt" className="label-form">
                    Aktivitas
                  </label>
                  <input className="input-form" id="activity_txt" {...register("activity_txt", { required: "This field is required" })} placeholder="Aktivitas Budget Plan" />
                  <ErrorField errors={errors} name="activity_txt" />
                </div>
              </div>

              <div className="w-full mt-5">
                <label htmlFor="commodity_name_var" className="label-form">
                  Komoditas
                </label>
                <Select
                  id="commodity_name_var"
                  styles={selectStylesForm}
                  isClearable
                  placeholder="- Pilih komoditas -"
                  options={dataCommodity}
                  getOptionValue={(option: any) => option["commodity_name_var"]}
                  getOptionLabel={(option: any) => option["commodity_name_var"]}
                  {...register("commodity_name_var")}
                  value={selectedCommodity}
                  onChange={setSelectedCommodity}
                />
                <ErrorField errors={errors} name="commodity_name_var" />
              </div>

              {/* <!-- Modal footer --> */}
              <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                <button type="submit" className="btn-primary">
                  Simpan
                </button>
                <button type="reset" className="btn-secondary px-7" onClick={onCancel}>
                  Batal
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default BPCategoryForm;
