import Dashboard from "./containers/Dashboard";
import Profile from "./containers/Profile";
import Login from "./containers/auth/Login";
import OtpPage from "./containers/auth/OtpPage";
import Register from "./containers/auth/Register";

import CommodityStandard from "./containers/admin/operatingparameter/CommodityStandard";
import ProjectManagement from "./containers/admin/ProjectManagement";
import User from "./containers/admin/User";

import LahanManagement from "./containers/admin/LahanManagement";
import UserGroup from "./containers/admin/UserGroup";
import OperatingParameter from "./containers/admin/operatingparameter/OperatingParameter";
import FarmerGroupManagement from "./containers/admin/FarmerGroupManagement";
import CultivationManagement from "./containers/admin/CultivationManagement";
import TaskManagement from "./containers/admin/TaskManagement";
import DataPanenManagement from "./containers/admin/DataPanenManagement";
import DataPanenApproval from "./containers/admin/DataPanenApproval";

import Analytics from "./containers/Analytics";
import Project from "./containers/Project";
import DashbboardAdmin from "./containers/dashboard/DashbboardAdmin";
import TaskPreference from "./containers/admin/operatingparameter/TaskReference";
import TaskPreferenceDetail from "./containers/admin/operatingparameter/TaskReferenceDetail";
import InventoryMonitoringStock from "./containers/admin/inventory/InventoryMonitoringStock";
import InventoryProductManagement from "./containers/admin/inventory/InventoryProductManagement";
import InventoryProductIn from "./containers/admin/inventory/InventoryEntryProduct";
import InventoryProductOut from "./containers/admin/inventory/InventoryExitProduct";
import InventoryProductMixing from "./containers/admin/inventory/InventoryCombinationProduct";
import BudgetPlanManagement from "./containers/admin/budgetplan/BudgetPlanManagement";
import BudgetPlanDetail from "./containers/admin/budgetplan/BudgetPlanDetail";
import BudgetApprovalDetail from "./containers/admin/budgetplan/BudgetApprovalDetail";
import BugetApprovalList from "./containers/admin/budgetplan/BugetApprovalList";
import BudgetPlanCategory from "./containers/admin/operatingparameter/BudgetPlanCategory";

export const RouteAdminRole = [
  {
    title: "Menu",
    routes: [
      {
        path: "",
        layout: "/dashboard",
        exact: true,
        name: "Dashboard",
      },
      {
        path: "/project-list",
        layout: "/dashboard",
        name: "Project",
      },
    ],
  },
  {
    title: "Transaction",
    routes: [
      {
        path: "/transaction-layanan",
        layout: "/dashboard",
        name: "Layanan",
      },
      {
        path: "/transaction-analytics",
        layout: "/dashboard",
        name: "Analysis",
      },
    ],
  },
  {
    title: "Admin",
    routes: [
      {
        path: "/project-management",
        layout: "/dashboard",
        name: "Project Management",
      },
      {
        path: "/land-management",
        layout: "/dashboard",
        name: "Lahan Management",
      },
      {
        path: "/commodity-standard",
        layout: "/dashboard",
        name: "Commodity Standard",
      },
      {
        path: "/probe-management",
        layout: "/dashboard",
        name: "Probe Management",
      },
      {
        path: "/probe-district-management",
        layout: "/dashboard",
        name: "Probe District",
      },
      {
        path: "/feature",
        layout: "/dashboard",
        name: "Feature",
      },
      {
        path: "/user-management",
        layout: "/dashboard",
        name: "User",
      },
    ],
  },
  {
    title: "Other",
    routes: [
      {
        path: "/profile",
        layout: "/dashboard",
        name: "Profile",
      },
    ],
  },
];

export const RoutePetaniRole = [
  {
    title: "Menu",
    routes: [
      {
        path: "",
        layout: "/dashboard",
        exact: true,
        name: "Dashboard",
      },
      {
        path: "/farmer-land",
        layout: "/dashboard",
        name: "Lahan",
      },
      {
        path: "/probe-activation",
        layout: "/dashboard",
        name: "Probe Activation",
      },
    ],
  },
  {
    title: "Other",
    routes: [
      {
        path: "/profile",
        layout: "/dashboard",
        name: "Profile",
      },
    ],
  },
];

export const RouteAnalisRole = [
  {
    title: "Menu",
    routes: [
      {
        path: "",
        layout: "/dashboard",
        exact: true,
        name: "Dashboard",
      },
      {
        path: "/land-analytics",
        layout: "/dashboard",
        name: "Analytics",
      },
      {
        path: "/history-analytics",
        layout: "/dashboard",
        name: "History",
      },
    ],
  },
  {
    title: "Other",
    routes: [
      {
        path: "/profile",
        layout: "/dashboard",
        name: "Profile",
      },
    ],
  },
];

export const RouteInvestorRole = [
  {
    title: "Menu",
    routes: [
      {
        path: "",
        layout: "/dashboard",
        exact: true,
        name: "Dashboard",
      },
      {
        path: "/project-list",
        layout: "/dashboard",
        name: "Project",
      },
      {
        path: "/analytics",
        layout: "/dashboard",
        name: "Analytics",
      },
      {
        path: "/schedule",
        layout: "/dashboard",
        name: "Schedule",
      },
    ],
  },
  {
    title: "Report",
    routes: [
      {
        path: "/daily",
        layout: "/dashboard",
        name: "Daily",
      },
      {
        path: "/monthly",
        layout: "/dashboard",
        name: "Monthly",
      },
      {
        path: "/yearly",
        layout: "/dashboard",
        name: "Yearly",
      },
    ],
  },
  {
    title: "Other",
    routes: [
      {
        path: "/profile",
        layout: "/dashboard",
        name: "Profile",
      },
    ],
  },
];


// DYNAMIC ROUTES
export const dashboardRoutes = [
  // DASHBOARD
  {
    path: "",
    component: <DashbboardAdmin />,
  },
  {
    path: "dashboard",
    component: <DashbboardAdmin />,
  },

  // PROJECT MANAGEMENT
  {
    path: "project-management-project",
    component: <ProjectManagement />,
  },
  {
    path: "project-management-land",
    component: <LahanManagement />,
  },

  // CULTIVATION MANAGEMENT
  {
    path: "cultivation-management-cultivation",
    component: <CultivationManagement />,
  },
  {
    path: "cultivation-management-task",
    component: <TaskManagement />,
  },
  {
    path: "cultivation-management-panen",
    component: <DataPanenManagement />,
  },
  
  // INVENTORY
  {
    path: "inventory-monitoring-stock",
    component: <InventoryMonitoringStock />,
  },
  {
    path: "inventory-daftar-produk",
    component: <InventoryProductManagement />,
  },
  {
    path: "inventory-barang-masuk",
    component: <InventoryProductIn />,
  },
  {
    path: "inventory-barang-keluar",
    component: <InventoryProductOut />,
  },
  {
    path: "inventory-pencampuran-produk",
    component: <InventoryProductMixing />,
  },

  // BUTGET PLAN
  {
    path: "budget-management-plan",
    component: <BudgetPlanManagement />,
  },
  {
    path: "budget-management-approval",
    component: <BugetApprovalList />,
  },
  
  // OPERATING PARAMETER
  {
    path: "operating-parameter-categoryreference",
    component: <BudgetPlanCategory />,
  },
  {
    path: "operating-parameter-commoditystandart",
    component: <CommodityStandard />,
  },
  {
    path: "operating-parameter-parameter",
    component: <OperatingParameter />,
  },
  {
    path: "operating-parameter-taskreference",
    component: <TaskPreference />,
  },

  // USER MANAGEMENT
  {
    path: "user-management-user",
    component: <User />,
  },
  {
    path: "user-management-usergroup",
    component: <UserGroup />,
  },
  {
    path: "user-management-farmergroup",
    component: <FarmerGroupManagement />,
  },
];


// STATIC ROUTES
export const dashboardStaticRoutes = [
  {
    path: "dashboard-agroobot",
    component: <Dashboard />,
  },
  {
    path: "cultivation-management-panen-approval/:id",
    component: <DataPanenApproval />,
  },
  {
    path: "budget-management-plan/:id",
    component: <BudgetPlanDetail />,
  },
  {
    path: "budget-management-approval/:id",
    component: <BudgetApprovalDetail />,
  },
  {
    path: "operating-parameter-taskreference/:id",
    component: <TaskPreferenceDetail />,
  },
  {
    path: "profile",
    component: <Profile />,
  },
  {
    path: "analytics",
    component: <Analytics />,
  },
  {
    path: "project",
    component: <Project />,
  }
]


export const routeAuth = [
  {
    path: "/sign-in",
    layout: "/auth",
    exact: true,
    name: "Login",
    component: <Login />,
  },
  {
    path: "/sign-up",
    layout: "/auth",
    name: "Register",
    component: <Register />,
  },
  {
    path: "/otp-validate",
    layout: "/auth",
    name: "OTP",
    component: <OtpPage />,
  },
];
