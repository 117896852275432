import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import TaskApi, { IActivity } from '../../../api/task-api';
import { GlobalContext } from '../../../context/GlobalProvider';
import Loader from '../Loader';

type Props = {
    data?: IActivity,
    onSubmit?: (data: IActivity) => any,
    onCancel?: (e?: React.MouseEvent<HTMLElement>) => any
};

const Activity: React.FC<Props> = ({ data, onSubmit, onCancel }) => {
    const [loader, showLoader] = useState(false)
    const { userStorage, ownerTask, setIsDataChanged } = useContext(GlobalContext)

    console.log(userStorage)
    const handleSubmitTask = async (e: React.ChangeEvent<HTMLFormElement>) => {
        showLoader(true)
        e.preventDefault()

        const { activity_txt, material_txt, dose_txt, jumlah_txt, satuan_txt } = e.target

        const payload = {
            activity_txt: activity_txt.value,
            material_txt: material_txt.value,
            dose_txt: dose_txt.value,
            jumlah_txt: jumlah_txt.value,
            satuan_txt: satuan_txt.value
        } as IActivity

        payload['user_id_int'] = ownerTask.user_id_int
        payload['land_code_var'] = ownerTask.land_code_var
        payload['period_plant_txt'] = ownerTask.period_plant_txt
        
        let res = null
        if(!data){
            payload['created_by_var'] = userStorage.fullname_var
            res = await TaskApi.createNewActivity(payload)
        }else{
            payload['updated_by_var'] = userStorage.fullname_var
            res = await TaskApi.updateActivity(data.id_seq, payload)
            // toast.info('Update activity in proggress')
            // showLoader(false)
            // return
        }
        
        console.log('Activity :', res)
        if(res.data && res.data.code === 0){
            toast.success(res.data.message)
            setIsDataChanged(true)
            // e.target.reset()
            if(onSubmit) onSubmit(payload)
            if(onCancel) onCancel()
        }else{
            showLoader(false)
            toast.error(res.data?.message || res.message)
        }
    }

    return (
        <>
            <form className='mt-5 md:px-4' onSubmit={handleSubmitTask} >
                <input name='id_seq' className='hidden' defaultValue={data?.id_seq || ''} />
                <div className='space-y-3'>
                    <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                        <div className="w-full">
                            <label htmlFor="activity_txt" className="label-form">Aktivitas</label>
                            <input name='activity_txt' id="activity_txt" className="input-task" defaultValue={data?.activity_txt || ''} placeholder="Penebaran benih + penutupan dengan jerami + Micoriza" required />
                        </div>
                    </div>
                    <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                        <div className="w-full">
                            <label htmlFor="material_txt" className="label-form">Bahan</label>
                            <input name='material_txt' id="material_txt" className="input-task" defaultValue={data?.material_txt || ''} placeholder="Benih POKOK" />
                        </div>
                        <div className="w-full">
                            <label htmlFor="dose_txt" className="label-form">Dosis</label>
                            <input name='dose_txt' id="dose_txt" className="input-task" defaultValue={data?.dose_txt || ''} placeholder="35 kg benih" />
                        </div>
                    </div>
                    <div className="md:flex md:space-x-8 space-y-5 md:space-y-0">
                        <div className="w-full">
                            <label htmlFor="jumlah" className="label-form">Jumlah HST</label>
                            <input type='number' name='jumlah_txt' id="jumlah" className="input-task" defaultValue={data?.jumlah_txt || ''} placeholder="18" required />
                        </div>
                        <div className="w-full">
                            <label htmlFor="satuan" className="label-form">Satuan HST</label>
                            <input id="satuan" name='satuan_txt' className="input-task" defaultValue={data?.satuan_txt || ''} placeholder="ST" required />
                        </div>
                    </div>
                </div>
                <footer className='flex justify-end mt-7 space-x-2'>
                    <button type='reset' className='text-gray-700 px-6 py-2.5 rounded-lg text-sm' onClick={onCancel}>Batal</button>
                    <button type='submit' className='bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-lg text-sm'>Simpan</button>
                </footer>
            </form>

            {loader && <Loader />}
        </>
    );
}

export default Activity;