import { useCallback, useContext, useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router"

import { createNewCommodityRef, deleteCommodityRef, getAllCommodityRef, updateCommodityRef } from "../../../api/commodity-api"

import TableUser from "../../../components/tables/Table"
import { ButtonAdd } from "../../../components/button/CustomButton"
import { DropdownLand } from "../../../components/dropdown/SimpleDropdown"

import ModalFormCommodityStandard from "../../../components/modal/operatingparameter/ModalFormCommodityStandard"
import Loader from "../../../components/modal/Loader";
import { toast } from "react-toastify"
import SearchField from "../../../components/SearchField"
import { ActionType } from "../../../reduxs/action/actions"
import { GlobalContext } from "../../../context/GlobalProvider"
import { getPayloadRecentActivity } from "../../../helpers/helper"


const TITLE_BAR = 'Operating Parameter / Commodity Standart'

const CommodityStandard = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [dataCommodity, setDataCommodity] = useState([])
    const [filterData, setFilterData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext)
  
    useEffect(() => {
      const payload = {
        id: new Date().getTime(),
        title: TITLE_BAR,
        path: pathname,
        url: window.location.href,
      };
  
      dispatchCalPage({ type: 'push', payload })
      dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
    }, [dispatch, dispatchCalPage, pathname])


    const fetchData = useCallback(async () => {
        showLoader(true)

        const res = await getAllCommodityRef()
        
        console.log('Get Commodity :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataCommodity(res.data.data)
                setFilterData(res.data.data)
            }else if(res.data.code === 99){
                showLoader(true)
                navigate('/auth', {replace: true})
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(`${res.config?.url} ${res.message}`)
        }
    }, [navigate])

    useEffect(() => {
        fetchData()
    }, [fetchData, dispatch])

    const handleEdit = (selectedData) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (payload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            payload.created_by_var = user.fullname_var
            res = await createNewCommodityRef(payload)

            var payloadRecentActivity = getPayloadRecentActivity(1, 'Commodity Standart', payload.id_seq, user?.fullname_var, pathname)
        }else{
            payload.updated_by_var = user.fullname_var
            res = await updateCommodityRef(selectedData.id_seq, payload)

            payloadRecentActivity = getPayloadRecentActivity(2, 'Commodity Standart', payload.id_seq, user?.fullname_var, pathname)
        }
        
        console.log('Create/Update Commmodity :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success(res.data.message)

                dispatchCalActivity({ type: 'push', payload: payloadRecentActivity })

                fetchData()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            toast.error(`${res.config?.url} ${res.message}`)
        }
    }


    const handleDeleteData = async (id_seq) => {
        showLoader(true)

        const res = await deleteCommodityRef(id_seq)

        console.log('Delete commodity Ref :', res)
        if(res.data){
            if(res.data.code === 0){
                toast.success(res.data.message)

                const payload = getPayloadRecentActivity(3, 'Commodity Standart', id_seq, user?.fullname_var, pathname)
                dispatchCalActivity({ type: 'push', payload })

                fetchData()
            }else if(res.data.code === 99){
                navigate('/auth', {replace: true})
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            toast.error(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        showLoader(false)
        setIsUpdate(false)
        showModalForm(false)
        setSelectedData(null)
    }

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataCommodity]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.commodity_name_var.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataCommodity)
        }
    }

    const columns = [
        {
            Header: () => <span className='pl-2 py-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }) =>  <div className='text-left pl-2'>{row.index + 1}.</div>,
        },
        {
            Header: 'Nama Komoditas',
            accessor: 'commodity_name_var',
        },
        {
            Header: 'Deskripsi',
            accessor: 'description_txt',
        },
        // {
        //     Header: 'Ketinggian',
        //     Footer: 'Ketinggian',
        //     accessor: 'elevation_var'
        // },
        // {
        //     Header: 'Curah Hujan',
        //     Footer: 'Curah Hujan',
        //     accessor: 'rainfall_var'
        // },
        // {
        //     Header: 'Topografi',
        //     Footer: 'Topografi',
        //     accessor: 'topography_var'
        // },
        // {
        //     Header: 'Suhu Udara',
        //     Footer: 'Suhu Udara',
        //     accessor: 'temperature_var'
        // },
        // {
        //     Header: 'Kelembaban',
        //     Footer: 'Kelembaban',
        //     accessor: 'humidity_var'
        // },
        // {
        //     Header: 'PH',
        //     Footer: 'PH',
        //     accessor: 'ph_value_var'
        // },
        // {
        //     Header: 'Nilai N',
        //     Footer: 'Nilai N',
        //     accessor: 'n_value_var'
        // },
        // {
        //     Header: 'Nilai P',
        //     Footer: 'Nilai P',
        //     accessor: 'p_value_var'
        // },
        // {
        //     Header: 'Nilai K',
        //     Footer: 'Nilai K',
        //     accessor: 'k_value_var'
        // },
        // {
        //     Header: 'Nilai ZA',
        //     Footer: 'Nilai ZA',
        //     accessor: 'za_value_var'
        // },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEdit(data)} onDelete={handleDeleteData} />
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
            <div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
                <div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
                    <h1 className='font-bold uppercase text-xl'>List COMMODITY STANDARD</h1>
                    <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
                        <SearchField onChange={handleSearch} placeholder='Cari data komoditas...' />
                        <ButtonAdd onClick={() => showModalForm(true)} />
                    </div>
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalFormCommodityStandard data={selectedData} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (CommodityStandard)