import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import dayjs from "dayjs";
import Select from 'react-select'
import { useDropdownProductListByType, useDropdownProductType } from "../../../hooks/useDropdownInventory";
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";
import { IEntryProduct } from "../../../api/inventory-api";
import Loader from "../Loader";

type Props = {
    data: IEntryProduct,
    onCancel: (e: React.MouseEvent) => any
    onSubmit: (data: IEntryProduct) => void
};

const MEASURE_LIST = [
    {value: 'kilogram', label: 'Kilogram (Kg)'},
    {value: 'unit', label: 'Unit'}
]

const ModalFormEntryProduct: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
    const [productTypeID, setProductTypeID] = useState<any>()
    const [selectedProduct, setSelectedProduct] = useState<any>()

    const { productTypeList, loadingProductType } = useDropdownProductType()
    const { productList, loadingProduct } = useDropdownProductListByType(productTypeID)

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {

        Object.keys(obj).map(key => {
            if (key === 'time_on_dte') {
                return setValue(key, dayjs(obj[key]).format('YYYY-MM-DD'))
            } else if(key === 'product_type_int'){
                return setValue(key, obj[key], { shouldValidate: true })
            } else if(key === 'id_product_var'){
                setSelectedProduct(productList.find(item => item.id_product_var === obj[key]))
                return setValue(key, obj[key], { shouldValidate: true })
            } else {
                return setValue(key, obj[key], { shouldValidate: true })
            }
        })
    }, [setValue, productList])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])

    useEffect(() => {
        if(data) {
            setProductTypeID(data.product_type_int!)
        }
        // eslint-disable-next-line
    }, [])

    const onSubmitForm: SubmitHandler<IEntryProduct> = ({ time_on_dte, unique_code_var, id_product_var, quantity_int, measure_var, price_int }) => {

        const payload = {
            time_on_dte, unique_code_var, id_product_var: selectedProduct?.id_product_var!, quantity_int, measure_var, price_int
        }

        if(parseInt(productTypeID) === 2 ){
            delete payload.unique_code_var
        } 
        
        onSubmit(payload)
    }

    return (
        <>
        {!loadingProductType?
        
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                            <h3>{data ? 'Ubah Data Barang Masuk' : 'Tambah Barang Masuk'}</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className='grid gap-5 grid-cols-1 md:grid-cols-2'>
                            <div className="w-full">
                                <label htmlFor="time_on_dte" className="label-form">Tanggal Masuk</label>
                                <input id='time_on_dte' type='date' className='input-form'
                                    {...register('time_on_dte', { required: 'This field is required' })}
                                />
                                <ErrorField errors={errors} name='time_on_dte' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="product_type_int" className="label-form">Tipe Produk</label>
                                <select id="product_type_int" className="input-form" {...register('product_type_int', { required: 'This field is required' })} onChange={(e)=>setProductTypeID(e.target.value)} value={productTypeID}>
                                    <option value=''>- Pilih tipe produk -</option>
                                    {productTypeList.map((item, key) =>
                                        <option value={item.id_seq} key={key}>{item.product_name_type_var}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='product_type_int' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="id_product_var" className="label-form">Nama Produk</label>
                                <Select id='id_product_var' styles={selectStylesForm} isClearable placeholder='- Pilih nama produk -'
                                    /* defaultValue={productList.find(item => item['id_product_var'].toLowerCase()=== 1 )} */ options={productList} isLoading={loadingProduct}
                                    getOptionValue={(option: any) => option['id_product_var']}
                                    getOptionLabel={(option: any) => `[${option['id_product_var']}] ${option['product_name_var']}`} 
                                    {...register('id_product_var')}
                                    // defaultValue={productList.find(item => item?.id_product_var === data?.id_product_var)}
                                    value={selectedProduct} onChange={setSelectedProduct}
                                    // onChange={e => console.log(e)}
                                />
                                <ErrorField errors={errors} name='id_product_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="measure_var" className="label-form">Satuan</label>
                                <select id="measure_var" className="input-form" {...register('measure_var', { required: 'This field is required' })}>
                                    <option value=''>- Pilih satuan -</option>
                                    {MEASURE_LIST.map((item, key) => 
                                        <option key={key} value={item.value}>{item.label}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='measure_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="quantity_int" className="label-form">Jumlah Barang</label>
                                <input id='quantity_int' type='number' className='input-form' placeholder="0"
                                    {...register('quantity_int', { required: 'This field is required' })}
                                />
                                <ErrorField errors={errors} name='quantity_int' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="price_int" className="label-form">Harga Barang</label>
                                <input id='price_int' type='number' className='input-form' placeholder="Rp."
                                    {...register('price_int', { required: 'This field is required' })}
                                />
                                <ErrorField errors={errors} name='price_int' />
                            </div>
                            {parseInt(productTypeID) !== 2 &&
                            <div className="w-full">
                                <label htmlFor="unique_code_var" className="label-form">Kode Unik</label>
                                <input id='unique_code_var' type='text' className='input-form' placeholder="0100422-01"
                                    {...register('unique_code_var', { required: 'This field is required' })}
                                />
                                <ErrorField errors={errors} name='unique_code_var' />
                            </div>
                            }
                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                            <button type='submit' className="btn-primary">Simpan</button>
                            <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                        </div>

                    </form>

                </div>
            </div>
            :
            <Loader />
        }
        </>
    )
}


export default ModalFormEntryProduct