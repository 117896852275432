import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import Select from 'react-select'
import dayjs from "dayjs";
import Loader from "../Loader";
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";
import useDropdownLand, { useDropdownPeriod } from "../../../hooks/useDropdownLand";
import useDropdownFarmer, { IUser } from "../../../hooks/useDropdownFarmer";

import { ILandArea } from '../../../api/landarea-api'
import { IBudgetPlan } from "../../../api/budgetplan-api";
import useDropdownCommodity from "../../../hooks/useDropdownCommodity";

type Props = {
    data: IBudgetPlan,
    onCancel: (e: React.MouseEvent) => any
    onSubmit: (data: IBudgetPlan) => void
};

const BPManagementForm: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
    const [selectedFarmer, setSelectedFarmer] = useState<IUser| any>(null)
    const [selectedLand, setSelectedLand] = useState<ILandArea | any>(null)
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null)
    const [selectedCommodity, setSelectedCommodity] = useState<any>(null)

    
    const { dataFarmer, loadingFarmer  } = useDropdownFarmer()
    const { dataLand, loadingLand } = useDropdownLand(selectedFarmer?.id_seq || 0)
    const { dataPeriod } = useDropdownPeriod(selectedLand?.land_code_var || '')
    const { dataCommodity } = useDropdownCommodity()

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<IBudgetPlan>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {

        Object.keys(obj).map((key:any) => {
            if (key === 'start_planting_dtm') {
                return setValue(key, dayjs(obj[key]).format('YYYY-MM-DD'))
            } else {
                return setValue(key, obj[key], { shouldValidate: true })
            }
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])

    useEffect(() => {
        if(data) {
            setSelectedFarmer(dataFarmer.find(item => item.id_seq === data.user_id_int))
            setSelectedCommodity(dataCommodity.find(item => item.commodity_name_var === data.commodity_name_var))
        }
        // eslint-disable-next-line
    }, [dataFarmer, dataCommodity])
    
    useEffect(() => {
        if(data) {
            setSelectedLand(dataLand.find(item => item.land_code_var === data.land_code_var))
        }
        // eslint-disable-next-line
    }, [dataLand])
    
    useEffect(() => {
        if(data) {
            setSelectedPeriod(dataPeriod.find(item => item.period_plant_txt === data.period_plant_txt))
        }
        // eslint-disable-next-line
    }, [dataPeriod])


    const onSubmitForm: SubmitHandler<IBudgetPlan> = ({ budget_plan_var  }) => {

        const payload = {
            commodity_name_var: selectedCommodity.commodity_name_var, 
            user_id_int: selectedFarmer.id_seq, 
            land_code_var: selectedLand.land_code_var, 
            period_plant_txt: selectedPeriod.period_plant_txt, 
            budget_plan_var
        }

        console.log(payload)
        onSubmit(payload)
    }

    return (
        <>
            {!loadingFarmer ?
                <div className="modal-form">
                    <div className="modal-form-outside" onClick={onCancel}></div>

                    <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                        <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                                <h3>Budget Plan Petani</h3>
                            </div>
                            <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                                onClick={onCancel}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                            <div className='grid gap-5 grid-cols-1 md:grid-cols-2'>
                                <div className="w-full">
                                    <label htmlFor="user_id_int" className="label-form">Petani</label>
                                    <Select id='user_id_int' styles={selectStylesForm} isClearable placeholder='- Pilih petani -' options={dataFarmer} isLoading={loadingFarmer}
                                        getOptionValue={(option: any) => option['id_seq']}
                                        getOptionLabel={(option: any) => option['fullname_var']}
                                        {...register('user_id_int')}
                                        value={selectedFarmer} onChange={setSelectedFarmer}
                                    />
                                    <ErrorField errors={errors} name='user_id_int' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="land_code_var" className="label-form">Lahan</label>
                                    <Select id='land_code_var' styles={selectStylesForm} isClearable placeholder='- Pilih lahan -' options={dataLand} isLoading={loadingLand}
                                        getOptionValue={(option: any) => option['land_code_var']}
                                        getOptionLabel={(option: any) => `[${option['land_code_var']}] ${option['land_name_var']}`}
                                        {...register('land_code_var')}
                                        value={selectedLand} onChange={setSelectedLand}
                                    />
                                    <ErrorField errors={errors} name='land_code_var' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="period_plant_txt" className="label-form">Periode</label>
                                    <Select id='period_plant_txt' styles={selectStylesForm} isClearable placeholder='- Pilih periode -' options={dataPeriod} isLoading={loadingLand}
                                        getOptionValue={(option: any) => option['period_plant_txt']}
                                        getOptionLabel={(option: any) => option['period_plant_txt']}
                                        {...register('period_plant_txt')}
                                        value={selectedPeriod} onChange={setSelectedPeriod}
                                    />
                                    <ErrorField errors={errors} name='period_plant_txt' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="commodity_name_var" className="label-form">Komoditas</label>
                                    <Select id='commodity_name_var' styles={selectStylesForm} isClearable placeholder='- Pilih komoditas -' options={dataCommodity}
                                        getOptionValue={(option: any) => option['commodity_name_var']}
                                        getOptionLabel={(option: any) => option['commodity_name_var']}
                                        {...register('commodity_name_var')}
                                        value={selectedCommodity} onChange={setSelectedCommodity}
                                    />
                                    <ErrorField errors={errors} name='commodity_name_var' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="budget_plan_var" className="label-form">Budget Plan (Rp)</label>
                                    <input className="input-form" id="budget_plan_var" {...register('budget_plan_var', {required: 'This field is required'} ) } placeholder='100.000' />
                                    <ErrorField errors={errors} name='budget_plan_var' />
                                </div>
                            </div>

                            {/* <!-- Modal footer --> */}
                            <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                                <button type='submit' className="btn-primary">Simpan</button>
                                <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                            </div>

                        </form>

                    </div>
                </div>
                :
                <Loader />
            }
        </>
    )
}


export default BPManagementForm