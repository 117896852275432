import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/modal/Loader';
import logo from './../../assets/img/calendar.png'
import dayjs from "dayjs";
import { generateCalendar } from '../../utils/generateCalendar';

import DayInCalendar, { ITask } from '../../components/DayInCalendar';
import TaskApi, { IActivity, IDocumentation, IObservartion, IRating } from '../../api/task-api';
import { toast } from 'react-toastify';
import Select from 'react-select'
import { useDispatch } from 'react-redux';
import { ActionType } from '../../reduxs/action/actions';
import { GlobalContext } from '../../context/GlobalProvider';

import useDropdownLand, { useDropdownPeriod } from '../../hooks/useDropdownLand';
import useDropdownFarmer from '../../hooks/useDropdownFarmer';
import ModalTaskView from '../../components/modal/TaskManagement/ModalTaskView';
import { ModalTaskNew } from '../../components/modal/TaskManagement/ModalTaskNew';

import 'dayjs/locale/id' // import locale
import { useLocation } from 'react-router-dom';
import { IRecentPage } from '../../context/ContextInterface';
dayjs.locale('id')


const TITLE_BAR = 'Cultivation Management / Task'

type Props = {};

const TaskManagement: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [monthIndex, setMonthIndex] = useState(dayjs().month())
    const [calendar, setCalendar] = useState(generateCalendar(dayjs().month()))
    const [modalTaskView, showModalTaskView] = useState(false)
    const [modalTaskNew, showModalTaskNew] = useState(false)

    const [dataTask, setDataTask] = useState<ITask>({} as ITask)
    const [selectedActivity, setSelectedActivity] = useState<IActivity[]>([])
    const [selectedObservation, setSelectedObservation] = useState<IObservartion[]>([])
    const [selectedDocumentation, setSelectedDocumentation] = useState<IDocumentation[]>([])
    const [selectedRating, setSelectedRating] = useState<IRating[]>([])
    const [selectedDay, setSelectedDay] = useState<any>(null)

    const [selectedLand, setSelectedLand] = useState<any>(null)
    const [selectedFarmer, setSelectedFarmer] = useState<any>(null)
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null)
    const [description, setDescription] = useState<any>({user: '', land: '', period: ''})

    const dispatch = useDispatch()
    const { pathname } = useLocation()

    const { dataFarmer, loadingFarmer  } = useDropdownFarmer()
    const { dataLand, loadingLand } = useDropdownLand(selectedFarmer?.id_seq || 0)
    const { dataPeriod, loadingPeriod } = useDropdownPeriod(selectedLand?.land_code_var || '')
    
    const { isDataChanged, setIsDataChanged, setOwnerTask, dispatchCalPage } = useContext(GlobalContext)


    useEffect(() => {
        setCalendar(generateCalendar(monthIndex))
    }, [monthIndex])
    

    useEffect(() => {
        const payload: IRecentPage = {
            id: new Date().getTime(),
            title: TITLE_BAR,
            path: pathname,
            url: window.location.href,
        };

        dispatchCalPage({ type: 'push', payload })
        dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
    }, [dispatch, dispatchCalPage, pathname])


    const fetchTask = async () => {
        setOwnerTask(null)

        if(selectedLand && selectedFarmer && selectedPeriod){
            showLoader(true)
            const res = await TaskApi.getLandTask(selectedFarmer.id_seq, selectedLand.land_code_var, selectedPeriod.period_plant_txt)
            console.log('Fetch Task :', res)
    
            showLoader(false)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataTask(res.data.data)
                    setDescription({user: selectedFarmer.fullname_var, land: `[${selectedLand.land_code_var}] ${selectedLand.land_name_var}`, period: selectedPeriod.period_plant_txt})
                    setOwnerTask({user_id_int: selectedFarmer.id_seq, land_code_var: selectedLand.land_code_var, period_plant_txt: selectedPeriod.period_plant_txt})
                } else {
                    setDataTask({} as ITask)
                    setDescription(null)
                    toast.error(res.data.message)
                }
            } else {
                toast.error(res.status + ' ' + res.message || 'Connection timeout...')
            }
            setIsDataChanged(false)
        }else{
            toast.warning('Silakan pilih petani, lahan dan periode terlebih dahulu!')
        }
    }


    const handlePrevMonth = () => {
        setMonthIndex(monthIndex - 1)
    }
    const handleNextMonth = () => {
        setMonthIndex(monthIndex + 1)
    }
    const handleToday = () => {
        setMonthIndex(dayjs().month())
    }

    const handleClickDate = (day: any, selectedActivity: IActivity[], selectedObs: IObservartion[], selectedDoc: IDocumentation[], selectedRate: IRating[]) => {
        if(!description.user || !description.land || !description.period){
            toast.warning('Silakan pilih petani, lahan dan periode terlebih dahulu!')
            return
        }

        setSelectedActivity(selectedActivity)
        setSelectedObservation(selectedObs)
        setSelectedDocumentation(selectedDoc)
        setSelectedRating(selectedRate)
        setSelectedDay(dayjs(day).format('YYYY-MM-DD'))
        showModalTaskNew(true)
    }
    
    const handleViewTask = (day: any, selectedActivity: IActivity[], selectedObs: IObservartion[], selectedDoc: IDocumentation[], selectedRate: IRating[]) => {
        setSelectedActivity(selectedActivity)
        setSelectedObservation(selectedObs)
        setSelectedDocumentation(selectedDoc)
        setSelectedRating(selectedRate)
        setSelectedDay(dayjs(day).format('YYYY-MM-DD'))
        showModalTaskView(true)
    }

    const handleCloseModal = () => {
        setSelectedActivity([])
        showModalTaskView(false)
        showModalTaskNew(false)
    }

    useEffect(() => {
        if(isDataChanged) fetchTask()
        // eslint-disable-next-line
    }, [isDataChanged])

    // console.log(isDataChanged)
    // console.log({selectedActivity, selectedObservation, selectedDocumentation, selectedRating})

    return (
        <>
            <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>

                <div className='w-full min-h-screen h-max flex flex-col bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
                    <div className='flex flex-col md:flex-row items-center justify-between pb-5 space-y-4 md:space-y-0'>
                        <h1 className='font-bold uppercase text-xl'>Task Farmer</h1>
                        <div className='w-full md:w-max flex items-center justify-between md:justify-center'>
                            <i className="fa-solid fa-chevron-left text-gray-600 text-base hover:text-gray-900 cursor-pointer" onClick={handlePrevMonth}></i>
                            <h2 className='font-medium text-xl text-gray-600 mx-10'>{dayjs(new Date(dayjs().year(), monthIndex)).format('MMMM YYYY')}</h2>
                            <i className="fa-solid fa-chevron-right text-gray-600 text-base hover:text-gray-900 cursor-pointer" onClick={handleNextMonth}></i>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row items-center justify-between pb-5 space-y-4 md:space-y-0'>
                        <div className='w-full md:w-max flex items-center justify-between md:justify-center'>
                            <div className='flex items-center'>
                                <img src={logo} alt='calendar' className='mr-2 w-11 h-11 md:w-12 md:h-12' />
                                <h1 className='mr-5 text-xl text-gray-600 font-medium'>Calendar</h1>
                            </div>
                            <button className='border rounded-full py-3 px-7 hover:bg-gray-100' onClick={handleToday}>Today</button>
                        </div>
                        <div className='w-full md:w-max flex flex-col md:flex-row items-center justify-between md:justify-center md:space-x-3 space-y-3 md:space-y-0'>
                            <Select name='farmer' styles={SelectCustomStyles} options={dataFarmer} onChange={setSelectedFarmer} isClearable placeholder='- Pilih Petani -' className='w-full md:w-[200px]' isLoading={loadingFarmer}
                                getOptionValue={(option: any) => option['id_seq']}
                                getOptionLabel={(option: any) => option['fullname_var']}
                            />
                            <Select name='land' styles={SelectCustomStyles} options={dataLand} onChange={setSelectedLand} isClearable placeholder='- Pilih Lahan -' className='w-full md:w-[200px] 2xl:md:w-[300px]' isLoading={loadingLand}
                                getOptionValue={(option: any) => option['land_code_var']}
                                getOptionLabel={(option: any) => `[${option['land_code_var']}] ${option['land_name_var']}`}
                            />
                            <Select name='period' styles={SelectCustomStyles} options={dataPeriod} onChange={setSelectedPeriod} isClearable placeholder='- Pilih Periode -' className='w-full md:w-[175px] 2xl:md:w-[200px]' isLoading={loadingPeriod}
                                getOptionValue={(option: any) => option['period_plant_txt']}
                                getOptionLabel={(option: any) => option['period_plant_txt']}
                            />
                            <button type='submit' className='w-full md:w-max bg-blue-600 hover:bg-blue-500 text-white px-6 py-3 rounded-lg text-sm' onClick={fetchTask}>Terapkan</button>
                        </div>
                    </div>
                    
                    {dataTask?.activity?.length! > 0 &&
                    <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-44 2xl:space-x-80 md:space-y-0 space-y-2 py-5 border-t z-0'>
                        <div className='flex flex-col md:flex-row items-center justify-between md:space-x-5 md:space-y-0 space-y-3'>
                            <span className='flex items-center justify-center w-18 h-18 2xl:w-20 2xl:h-20 bg-gray-200 text-gray-400 rounded-lg text-xl'>
                                <i className="fa-solid fa-user"></i>
                            </span>
                            <table className="text-sm md:text-lg font-semibold">
                                <tbody>
                                    <tr>
                                        <td className='pr-5 md:pr-10'>Petani</td>
                                        <td>: {description?.user}</td>
                                    </tr>
                                    <tr>
                                        <td>Lahan</td>
                                        <td>: {description?.land}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='w-[2px] h-full bg-slate-300' />
                        <div className='text-lightcayn font-bold text-xl'>
                            <p>Periode {description?.period}</p>
                        </div>
                    </div>
                    }

                    <div className='grid grid-cols-7 grid-rows-1 uppercase font-medium text-center text-sms'>
                        <div className='border-t border-l border-r border-slate-200 py-2 text-red-400'>Min</div>
                        <div className='border-t border-l border-r border-slate-200 py-2'>Sen</div>
                        <div className='border-t border-l border-r border-slate-200 py-2'>Sel</div>
                        <div className='border-t border-l border-r border-slate-200 py-2'>Rab</div>
                        <div className='border-t border-l border-r border-slate-200 py-2'>Kam</div>
                        <div className='border-t border-l border-r border-slate-200 py-2'>Jum</div>
                        <div className='border-t border-l border-r border-slate-200 py-2'>Sab</div>
                    </div>

                    <div className='flex-1 grid grid-cols-7 grid-rows-5 z-0'>
                        {calendar.map((week: any[], key) =>
                            <React.Fragment key={key}>
                                {week.map((day, keyx) =>
                                    <DayInCalendar key={keyx}
                                        day={day}
                                        monthIndex={monthIndex}
                                        onViewTask={handleViewTask}
                                        onClickDate={handleClickDate}
                                        tasks={dataTask!}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>

                {loader && <Loader />}
                {modalTaskView &&
                    <ModalTaskView
                        daySelected={selectedDay}
                        activites={selectedActivity}
                        observation={selectedObservation} 
                        documentation={selectedDocumentation} 
                        rating={selectedRating} 
                        onClose={handleCloseModal}
                    />
                }
                {modalTaskNew && 
                    <ModalTaskNew 
                        daySelected={selectedDay}
                        observation={selectedObservation} 
                        documentation={selectedDocumentation} 
                        rating={selectedRating} 
                        onClose={handleCloseModal} 
                    />
                }
            </div>
        </>
    );
}

export const SelectCustomStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: 6,
        fontSize: 14,
        padding: '3px 3px',
        cursor: 'pointer',
        border: 'solid 1px #d0d8d0'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        fontWeight: 500,
        cursor: 'pointer',
        fontSize: 14,
        padding: '12px 12px'
    })
}

export default TaskManagement;