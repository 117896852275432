import { useCallback, useContext, useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"

import Helper, { getPayloadRecentActivity } from "../../helpers/helper"
import { createNewUser, disableUser, getAllUser, updateUser } from "../../api/user-api"

import TableUser from "../../components/tables/Table"
import { ButtonAdd } from "../../components/button/CustomButton"

import ModalFormUser from "../../components/modal/ModalFormUser"
import Loader from "../../components/modal/Loader";
import DropdownTableUser from "../../components/dropdown/DropdownTableUser"
import ModalFormChangePassword from "../../components/modal/ModalFormChangePassword"
import { toast } from "react-toastify"
import FarmerGroupApi from "../../api/farmergroup-api"
import SearchField from "../../components/SearchField"
import { ActionType } from "../../reduxs/action/actions"
import { GlobalContext } from "../../context/GlobalProvider"
import { useLocation } from "react-router-dom"

const TITLE_BAR = 'User Management / User'

const User = ({user, titleBar}) => {
    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [modalChangePass, showModalChangePass] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [farmerGroupList, setFarmerGroupList] = useState([])

    const dispatch = useDispatch()
    const { dispatchCalActivity, dispatchCalPage } = useContext(GlobalContext)
    const {pathname} = useLocation();

    useEffect(() => {
        const payload = {
            id: new Date().getTime(),
            title: TITLE_BAR,
            path: pathname,
            url: window.location.href,
        };
        
        dispatchCalPage({type: 'push', payload})
        dispatch({type: ActionType.SET_TITLE_BAR, value: TITLE_BAR})
    }, [dispatch, dispatchCalPage, pathname])

    const fetchUser = useCallback( async () => {
        showLoader(true)
        const res = await getAllUser(localStorage.getItem('pandawa-token'))

        showLoader(false)
        console.log('User Management :', res)
        if(res.data){
            if(res.data.code === 0){
                setDataUser(res.data.data)
                setFilterData(res.data.data)
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }, [])

    async function fetchFarmerGroup() {
        const res = await FarmerGroupApi.getAll()
        console.log("fetching farmer group :", res)

        if (res.data) {
            if (res.data.code === 0) {
                setFarmerGroupList(res.data.data)
            }
        }
    }

    useEffect(() => {
        fetchUser()
        fetchFarmerGroup()
    }, [fetchUser])


    const handleEditData =  (selectedData) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleChangePassword = (selectedData) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalChangePass(true)
    }

    const handleReceiveDataForm = async (data) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            data.created_by_var = user.fullname_var
            res = await createNewUser(data)

            var payload = getPayloadRecentActivity(1, 'User', data.fullname_var, user.fullname_var, pathname)
        }else{
            data.updated_by_var = user.fullname_var
            res = await updateUser(selectedUser.id_seq, data)

            payload = getPayloadRecentActivity(2, 'User', data.fullname_var, user.fullname_var, pathname)
        }

        console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success(res.data.message)

                dispatchCalActivity({type: 'push', payload})

                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const handleDeleteItem = async (userID) => {
        const res = await disableUser(userID)

        console.log("DELETE USER :", res)
        if(res.data){
            if(res.data.code === 0){
                const payload = getPayloadRecentActivity(3, 'User', userID, user.fullname_var, pathname)

                dispatchCalActivity({type: 'push', payload})

                toast.success(res.data.message)
                fetchUser()
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const resetForm = () => {
        setSelectedUser(null)
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
        showModalChangePass(false)
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }) =>  <div className='text-left pl-4'>{row.index + 1}.</div>,
        },
        {
            Header: 'Name',
            accessor: 'fullname_var',
        },
        {
            Header: 'Phone Number',
            Footer: 'Phone Number',
            accessor: 'phone_number_int',
        },
        {
            Header: 'Email',
            Footer: 'Email',
            accessor: 'email_var'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}) => (
                parseInt(value)===1? 
                <span className='bg-green-100 text-green-800 px-2 py-1 rounded-xl font-medium'>Active</span>
                :
                <span className='bg-red-100 text-red-800 px-2 py-1 rounded-xl font-medium'>Inactive</span>
            )
        },
        {
            Header: 'Role',
            Footer: 'Role',
            accessor: 'user_group_id_int',
            Cell: ({ value }) => {
                return Helper.getUserRole(value)
            }
        },
        {
            Header: 'FarmerGroup',
            accessor: 'farmer_group_id_int',
            Cell: ({ value }) => {
                return farmerGroupList.find(item => parseInt(item.id_seq) === parseInt(value))?.farmer_group_name_var || ''
            }
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                if(user?.user_group_id_int === 1){
                    if(data.user_group_id_int !== 1){
                        return <DropdownTableUser
                            onEdit={() => handleEditData(data)}
                            onDelete={() => handleDeleteItem(data.id_seq)}
                            onChangePassword={()=>handleChangePassword(data)}
                        />
                    }
                }else{
                    if(data.user_group_id_int !== 1 && data.user_group_id_int !== 2){
                        return <DropdownTableUser
                            onEdit={() => handleEditData(data)}
                            onDelete={() => handleDeleteItem(data.id_seq)}
                            onChangePassword={()=>handleChangePassword(data)}
                        />
                    }
                }

                return <span></span>
            }
        }
    ]

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.fullname_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.phone_number_int.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email_var?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    Helper.getUserRole(item.user_group_id_int)?.toLowerCase().includes(event.target.value.toLowerCase()) 
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }


    return (
        <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>

            <div className='flex flex-col md:flex-row items-center justify-between mb-6 2xl:mb-8'>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green-50 rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <i className="fa-solid fa-users"></i>
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.length}</h1>
                        <p>Total User</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green-50 rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <i className="fa-solid fa-user-check"></i>
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.filter(data => data.status_int === 1).length}</h1>
                        <p>User Aktif</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green-50 rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <i className="fa-solid fa-user-large-slash"></i>
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.filter(data => data.status_int !== 1).length}</h1>
                        <p>User Tidak Aktif</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green-50 rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <i className="fa-solid fa-people-group"></i>
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.filter(data => data.user_group_id_int === 4).length}</h1>
                        <p>Total Petani</p>
                    </div>
                </div>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
                <div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
                    <h1 className='font-bold uppercase text-xl'>List User</h1>
                    <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
                        <SearchField onChange={handleSearch} placeholder='Cari user...' />
                        <ButtonAdd onClick={() => showModalUser(true)} />
                    </div>
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalUser && <ModalFormUser 
                title={isUpdate? 'Form Ubah User' : 'Form Tambah User Baru'} 
                data={selectedUser}
                farmerGroupList={farmerGroupList}
                onCancel={resetForm}
                onSubmit={handleReceiveDataForm}
            />}
            {modalChangePass && <ModalFormChangePassword onCancel={()=>showModalChangePass(false)} onSubmit={handleReceiveDataForm} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        titleBar: state.title_bar
    }
}

export default connect(mapStateToProps, null)(User)