import ReactApexChart from "react-apexcharts"

const series = [
    { name: "Suhu", data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26] },
    { name: "PH", data: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34] }
]

const options = {
	chart: { type: "area", toolbar: { show: !1 } },
	colors: ["#556ee6", "#f1b44c"],
	dataLabels: { 
        enabled: !1 
    },
	stroke: { 
        curve: "smooth", width: 2
    },
	fill: {
		type: "gradient",
		gradient: { 
            shadeIntensity: 1, inverseColors: !1, opacityFrom: .60, opacityTo: .05, stops: [50, 100, 100, 100] 
        }
	},
	xaxis: { 
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"] 
    },
	markers: { 
        size: 3, strokeWidth: 3, hover: { size: 4, sizeOffset: 2 } 
    },
	legend: { 
        position: "top", horizontalAlign: "right" 
    }
}

const Analytics = () => {
    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>ANALYTICS</h1>
                <h2 className='text-base font-medium'>Menu<span className='text-xs'> / </span><span className='text-sm'>Analytics</span></h2>
            </div>
            <div className='flex flex-col lg:flex-row'>
                <div className='w-full mr-5 mb-5'>
                    <div className='flex flex-col md:flex-row items-center justify-between mb-5 font-medium'>
                        <div className='w-full lg:w-4/12 p-5 bg-white rounded-2xl shadow-xl flex items-center justify-between'>
                            <div>
                                <p>Nilai PH</p>
                                <h2 className='mt-2 text-lg font-semibold'>00</h2>
                            </div>
                            <span className='rounded-full p-1 w-10 h-10 flex items-center justify-center bg-soft'><i className="fa-solid fa-thermometer"></i></span>
                        </div>
                        <div className='w-full lg:w-4/12 p-5 bg-white rounded-2xl shadow-xl flex items-center justify-between mx-5 my-5 lg:my-0'>
                            <div>
                                <p>Nilai Humidity</p>
                                <h2 className='mt-2 text-lg font-semibold'>00</h2>
                            </div>
                            <span className='rounded-full p-1 w-10 h-10 flex items-center justify-center bg-soft'><i className="fa-solid fa-water"></i></span>
                        </div>
                        <div className='w-full lg:w-4/12 p-5 bg-white rounded-2xl shadow-xl flex items-center justify-between'>
                            <div>
                                <p>Kecepatan Angin</p>
                                <h2 className='mt-2 text-lg font-semibold'>00</h2>
                            </div>
                            <span className='rounded-full p-1 w-10 h-10 flex items-center justify-center bg-soft'><i className="fa-solid fa-wind"></i></span>
                        </div>
                    </div>
                    <div className='w-full p-5 bg-white rounded-2xl shadow-2xl'>
                        <div className='pb-4 border-b-2 border-gray-200 flex items-center justify-between'>
                            <h2 className='font-semibold'>Data Analisis</h2>
                            <div>
                                <button>Bulanan</button>
                                <button className='mx-3'>Mingguan</button>
                                <button>Harian</button>
                            </div>
                        </div>
                        <div className='pt-5'>
                            <ReactApexChart 
                                options={options}
                                series={series}
                                height={350}
                                type='area'
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full h-40 lg:h-64 lg:w-5/12 p-5 bg-white rounded-2xl shadow-2xl'>
                    <div className='flex justify-between'>
                        <div>
                            <h2 className='font-semibold mb-5'>Data Rekomendasi</h2>
                            <p>On Development</p>
                        </div>
                        <i className="fa-solid fa-ellipsis"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics