import axios from "axios";
import { baseURL } from "./common-api";

export interface ICommodity {
  id_seq?: number;
  user_id_int: number;
  land_code_var?: string;
  commodity_name_var: string;
  commodity_type_var: string;
  start_planting_dtm?: string;
  period_plant_txt?: string;
  harvest_prediction_int?: number;
  planting_method_int?: number;
  planting_phase_int?: number;
  source_funding_var: string;
  file_template_txt: string,
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export const dropdownPlantingMethod = async () => {
  try {
    const data = await axios.get(baseURL + "/api/commodity/dropdownPlantingMethod", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownPlantingPhase = async () => {
  try {
    const data = await axios.get(baseURL + "/api/commodity/dropdownPlantingPhase", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownCommodityName = async () => {
  try {
    const data = await axios.get(baseURL + "/api/commodity/dropdownCommodity", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownPlantMethodByCommodity = async (commodityName: string) => {
  try {
    const data = await axios.get(baseURL + "/api/commodity/dropdownPlantingMethod?commodity_name_var=" + commodityName, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const dropdownTemplateTask = async (commodityName: string, planting_method_int: number) => {
  try {
    const data = await axios.get(`${baseURL}/api/commodity/dropdownTemplate?commodity_name_var=${commodityName}&planting_method_int=${planting_method_int}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
}

export const getAllCommodity = async () => {
  try {
    const data = await axios.get(baseURL + "/api/commodity/get-all", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const getCommodityListByUser = async (userID: number) => {
  try {
    const data = await axios.get(baseURL + "/api/commodity/get-list-user?user_id_int=" + userID, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNewCommodity = async (payload: ICommodity) => {
  try {
    const data = await axios.post(baseURL + "/api/commodity/create", payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const updateCommodity = async (commodityID: number, payload: ICommodity) => {
  try {
    const data = await axios.patch(baseURL + "/api/commodity/update/" + commodityID, payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteCommodity = async (commodityID: number) => {
  try {
    const data = await axios.delete(baseURL + "/api/commodity/delete/" + commodityID, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const disableCommodity = async (commodityID: number) => {
  try {
    const data = await axios.delete(baseURL + "/api/commodity/disable/" + commodityID, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

//#region COMMODITY-REF
export const getAllCommodityRef = async () => {
  try {
    const data = await axios.get(baseURL + "/api/commodity-ref/get-all", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const getOneCommodityRef = async (commodityName: string) => {
  try {
    const data = await axios.get(baseURL + "/api/commodity-ref/get-one?commodity_name_var=" + commodityName, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const createNewCommodityRef = async (payload: any) => {
  try {
    const data = await axios.post(baseURL + "/api/commodity-ref/create", payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const updateCommodityRef = async (commodityRefID: string, payload: any) => {
  try {
    const data = await axios.patch(baseURL + "/api/commodity-ref/update/" + commodityRefID, payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};

export const deleteCommodityRef = async (commodityRefID: number) => {
  try {
    const data = await axios.delete(baseURL + "/api/commodity-ref/delete/" + commodityRefID, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: any) {
    if (error.response) return error.response;
    else return JSON.parse(JSON.stringify(error));
  }
};
//#endregion
