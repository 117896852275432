import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import Select from 'react-select'
import dayjs from "dayjs";
import { selectStylesForm } from "../TaskManagement/ModalTaskNew";
import { useDropdownActivityBP, useDropdownCategoryBP } from "../../../hooks/useDropdownBudgetPlanDetail";
import { IBudgetDetail } from "../../../api/budgetplan-api";

const MEASURE_LIST = [
    {value: 'kilogram', label: 'Kilogram (Kg)'},
    {value: 'unit', label: 'Unit'},
    { value: 'paket', label: 'Paket' },
    { value: 'hok', label: 'HOK' }
]

type Props = {
    data: IBudgetDetail,
    onCancel: (e: React.MouseEvent) => any
    onSubmit: (data: FormData) => void
};

const BPDetailForm: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
    const [selectedCategory, setSelectedCategory] = useState<any>(null)
    const [selectedActivity, setSelectedActivity] = useState<any>(null)

    const { categoryList, loadingCategory } = useDropdownCategoryBP()
    const { activityList, loadingActivity } = useDropdownActivityBP(selectedCategory?.category_var || '')


    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {

        Object.keys(obj).map(key => {
            if (key === 'start_planting_dtm') {
                return setValue(key, dayjs(obj[key]).format('YYYY-MM-DD'))
            } else {
                return setValue(key, obj[key], { shouldValidate: true })
            }
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])

    useEffect(() => {
        if(data){
            setSelectedCategory(categoryList.find(item => item.category_var === data.category_var))
        }
		// eslint-disable-next-line
    }, [categoryList])

    useEffect(() => {
        if(data){
            setSelectedActivity(activityList.find(item => item.activity_txt === data.activity_txt))
        }
		// eslint-disable-next-line
    }, [activityList])

    const onSubmitForm: SubmitHandler<IBudgetDetail> = ({ area_var, quantity_var, satuan_var, price_var, total_price_var, images }) => {

        const formData = new FormData()

        formData.append('activity_txt', selectedActivity.activity_txt)
        formData.append('category_var', selectedCategory.category_var)
        formData.append('area_var', area_var)
        formData.append('quantity_var', quantity_var)
        formData.append('satuan_var', satuan_var)
        formData.append('price_var', price_var)
        // formData.append('total_price_var', total_price_var)

        if(images.length > 0){
            for (let i = 0; i < images.length; i++) {
                formData.append('images', images[i])
            }
        }

        onSubmit(formData)
    }

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                            <h3>Budget Plan</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className='grid gap-5 grid-cols-1'>
                            <div className="w-full">
                                <label htmlFor="category_var" className="label-form">Kategori</label>
                                <Select id='category_var' styles={selectStylesForm} isClearable placeholder='- Pilih kategori -' options={categoryList} isLoading={loadingCategory}
                                    getOptionValue={(option: any) => option['category_var']}
                                    getOptionLabel={(option: any) => option['category_var']}
                                    {...register('category_var')}
                                    value={selectedCategory} onChange={setSelectedCategory}
                                />
                                <ErrorField errors={errors} name='category_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="activity_txt" className="label-form">Kegiatan</label>
                                <Select id='activity_txt' styles={selectStylesForm} isClearable placeholder='- Pilih kegiatan -' options={activityList} isLoading={loadingActivity}
                                    getOptionValue={(option: any) => option['activity_txt']}
                                    getOptionLabel={(option: any) => option['activity_txt']}
                                    {...register('activity_txt')}
                                    value={selectedActivity} onChange={setSelectedActivity}
                                />
                                <ErrorField errors={errors} name='activity_txt' />
                            </div>
                            <div className='grid gap-5 grid-cols-1 md:grid-cols-2'>
                                <div className="w-full">
                                    <label htmlFor="area_var" className="label-form">Luas</label>
                                    <input id='area_var' type='number' className='input-form'
                                        {...register('area_var', { required: 'This field is required' })}
                                    />
                                    <ErrorField errors={errors} name='area_var' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="quantity_var" className="label-form">Jumlah</label>
                                    <input id='quantity_var' type='number' className='input-form' placeholder="0"
                                        {...register('quantity_var', { required: 'This field is required' })}
                                    />
                                    <ErrorField errors={errors} name='quantity_var' />
                                </div>
                            </div>
                            <div className='grid gap-5 grid-cols-1 md:grid-cols-2'>
                                <div className="w-full">
                                    <label htmlFor="satuan_var" className="label-form">Satuan</label>
                                    <select id="satuan_var" className="input-form" {...register('satuan_var', { required: 'This field is required' })}>
                                        <option value=''>- Pilih satuan -</option>
                                        {MEASURE_LIST.map((item, key) =>
                                            <option key={key} value={item.value}>{item.label}</option>
                                        )}
                                    </select>
                                    <ErrorField errors={errors} name='satuan_var' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="price_var" className="label-form">Harga</label>
                                    <input id='price_var' type='number' className='input-form' placeholder="Rp."
                                        {...register('price_var', { required: 'This field is required' })}
                                    />
                                    <ErrorField errors={errors} name='price_var' />
                                </div>
                                {/* <div className="w-full">
                                    <label htmlFor="total_price_var" className="label-form">Total Harga</label>
                                    <input id='total_price_var' type='number' className='input-form' placeholder="Rp."
                                        {...register('total_price_var', { required: 'This field is required' })}
                                    />
                                    <ErrorField errors={errors} name='total_price_var' />
                                </div> */}
                            </div>
                            <div className="w-full">
                                <label htmlFor="images" className="label-form">Dokumentasi</label>
                                <input id='images' type='file' multiple className='input-form' accept=".png, .jpg, .jpeg, .bmp, .gif"
                                    {...register('images')}
                                />
                                <ErrorField errors={errors} name='images' />
                            </div>
                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                            <button type='submit' className="btn-primary">Simpan</button>
                            <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    )
}


export default BPDetailForm