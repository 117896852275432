import { ChangeEvent, useState } from "react";
import dayjs from "dayjs";
import Select from 'react-select'
import Loader from './Loader';
import useDropdownLand, { useDropdownPeriod } from "../../hooks/useDropdownLand";
import useDropdownCommodity from "../../hooks/useDropdownCommodity";
import { selectStylesForm } from "./TaskManagement/ModalTaskNew";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IDataPanen } from "../../api/datapanen-api";


type Props = {
    data: IDataPanen,
    onCancel: (e: React.MouseEvent) => any
    onSubmit?: (data: IDataPanen | any) => void
};

const ModalFormPanen: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
    const { dataCommodity, loadingCommodity } = useDropdownCommodity()
    // const [selectedCommodity, setSelectedCommodity] = useState<any>(data? dataCommodity.find(item=> item.commodity_name_var === data.commodity_name_var) : null)

    const { dataLand, loadingLand } = useDropdownLand(0)       // eslint-disable-line
    const [selectedLand, setSelectedLand] = useState<any>(data ? data.land_code_var : '')

    const { dataPeriod, loadingPeriod } = useDropdownPeriod(selectedLand)
    // const [selectedPeriod, setSelectedPeriod] = useState<any>(data? dataPeriod.find(item => item.period_plant_txt === data.period_plant_txt) : null)


    const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        const formdata = new FormData(e.target)

        // const { land_code_var, commodity_name_var, period_plant_txt, harvest_flo, harvest_on_dte, harvest_drying_dte, harvest_drying_flo, harvest_milling_dte, harvest_milling_flo, images } = e.target

        // const formdata = new FormData()
        // formdata.append('land_code_var', land_code_var.value)
        // formdata.append('commodity_name_var', commodity_name_var.value)
        // formdata.append('period_plant_txt', period_plant_txt.value)
        // formdata.append('harvest_flo', harvest_flo.value)
        // formdata.append('harvest_on_dte', harvest_on_dte.value)
        // formdata.append('harvest_drying_dte', harvest_drying_dte.value)
        // formdata.append('harvest_drying_flo', harvest_drying_flo.value)
        // formdata.append('harvest_milling_dte', harvest_milling_dte.value)
        // formdata.append('harvest_milling_flo', harvest_milling_flo.value)

        // const files = images.files
        // if(files.length > 0){
        //     for (let i = 0; i < files.length; i++) {
        //         formdata.append('images', files[i])
        //     }
        // }
        // console.log(Object.fromEntries(formdata))
        if (onSubmit) {
            onSubmit(formdata)
        }
    }

    return (
        <>
            {!loadingLand && !loadingCommodity ?
                <div className="modal-form">
                    <div className="modal-form-outside" onClick={onCancel}></div>

                    <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                        <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                            <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                                <h3>{data ? 'Ubah Data Panen' : 'Tambah Data Panen'}</h3>
                            </div>
                            <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                                onClick={onCancel}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                            <div className='space-y-5'>
                                <h2 className="text-sm font-bold text-lightcayn">Informasi Panen</h2>
                                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                    <div className="w-full">
                                        <label className="label-form">Komoditas</label>
                                        <Select name='commodity_name_var' styles={selectStylesForm} options={dataCommodity} isLoading={loadingCommodity}
                                            defaultValue={data ? dataCommodity.find(item => item.commodity_name_var === data.commodity_name_var) : null}
                                            // value={selectedCommodity} onChange={setSelectedCommodity}
                                            getOptionValue={(option: any) => option['commodity_name_var']}
                                            getOptionLabel={(option: any) => option['commodity_name_var']}
                                            isClearable placeholder='- Pilih Komoditas -'
                                        />
                                    </div>
                                    <div className="w-full">
                                        <label className="label-form">Lahan</label>
                                        <Select name='land_code_var' styles={selectStylesForm} options={dataLand} isLoading={loadingLand}
                                            defaultValue={data ? dataLand.find(item => item.land_code_var === data.land_code_var) : ''}
                                            onChange={(item: any) => setSelectedLand(item.land_code_var)}
                                            getOptionValue={(option: any) => option['land_code_var']}
                                            getOptionLabel={(option: any) => `[${option['land_code_var']}] ${option['land_name_var']}`}
                                            isClearable placeholder='- Pilih Lahan -'
                                        />
                                    </div>
                                </div>

                                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                    <div className="w-full">
                                        <label className="label-form">Periode Tanam</label>
                                        {data ?
                                            dataPeriod.length > 0 ?
                                                <Select name='period_plant_txt' styles={selectStylesForm} options={dataPeriod} isLoading={loadingPeriod}
                                                    defaultValue={data ? dataPeriod.find(item => item.period_plant_txt === data.period_plant_txt) : ''}
                                                    // value={selectedPeriod} onChange={setSelectedPeriod}
                                                    getOptionValue={(option: any) => option['period_plant_txt']}
                                                    getOptionLabel={(option: any) => option['period_plant_txt']}
                                                    isClearable placeholder='- Pilih Periode -'
                                                /> : null
                                            :
                                            <Select name='period_plant_txt' styles={selectStylesForm} options={dataPeriod} isLoading={loadingPeriod}
                                                getOptionValue={(option: any) => option['period_plant_txt']}
                                                getOptionLabel={(option: any) => option['period_plant_txt']}
                                                isClearable placeholder='- Pilih Periode -'
                                            />
                                        }
                                    </div>
                                    <div className="w-full">
                                        <label htmlFor="harvest_flo" className="label-form">Hasil Panen (Kg)</label>
                                        <input id='harvest_flo' name="harvest_flo" type='number' className='input-form' defaultValue={data?.harvest_flo} placeholder='10' />
                                    </div>
                                </div>
                                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0 md:mr-10">
                                    <div className="w-full md:w-1/2">
                                        <label htmlFor="harvest_on_dte" className="label-form">Tanggal Panen</label>
                                        <input id='harvest_on_dte' name="harvest_on_dte" type='date' className='input-form' defaultValue={data ? dayjs(data?.harvest_on_dte).format('YYYY-MM-DD') : ''} />
                                    </div>
                                </div>

                            </div>

                            <h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">Hasil Panen Penjemuran</h2>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="harvest_drying_dte" className="label-form">Tanggal Penjemuran</label>
                                    <input id='harvest_drying_dte' name="harvest_drying_dte" type='date' className='input-form' defaultValue={data ? dayjs(data?.harvest_drying_dte).format('YYYY-MM-DD') : ''} />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="harvest_drying_flo" className="label-form">Hasil Penjemuran (Kg)</label>
                                    <input id='harvest_drying_flo' name="harvest_drying_flo" type='number' className='input-form' defaultValue={data?.harvest_drying_flo} placeholder='10' />
                                </div>
                            </div>

                            <h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">Hasil Panen Penggilingan</h2>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="harvest_milling_dte" className="label-form">Tanggal Penggilingan</label>
                                    <input id='harvest_milling_dte' name="harvest_milling_dte" type='date' className='input-form' defaultValue={data ? dayjs(data?.harvest_milling_dte).format('YYYY-MM-DD') : ''} />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="harvest_milling_flo" className="label-form">Hasil Penggilingan (Kg)</label>
                                    <input id='harvest_milling_flo' name="harvest_milling_flo" type='number' className='input-form' defaultValue={data?.harvest_milling_flo} placeholder='10' />
                                </div>
                            </div>

                            <h2 className="text-sm font-bold text-lightcayn mb-4 mt-8">Dokumentasi Panen</h2>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="images" className="label-form">Unggah Bukti Panen</label>
                                    <input id='images' name="images" type='file' className='input-form' accept='.png, .jpg, .jpeg, .bmp' multiple />
                                </div>
                            </div>

                            {/* <!-- Modal footer --> */}
                            <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                                <button type='submit' className="btn-primary">Simpan</button>
                                <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                            </div>

                        </form>

                    </div>
                </div>
                :
                <Loader />
            }
        </>
    )
}


export const ModalFormPanenDetail: React.FC<Props> = ({ data, onCancel }) => {
    const getStatusStyle = () => {
        switch (data.status_int) {
            case -5:
                return 'bg-red-50 text-red-500'
            case 400:
                return 'bg-red-50 text-red-500'
            case 100:
                return 'bg-slate-100 text-slate-500'
            case 150:
                return 'bg-blue-50 text-blue-500'
            default:
                return 'bg-green-50 text-green-500'
        }
    }

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 md:px-8 rounded-t border-b border-slate-300">
                        <div className="text-base md:text-xl font-semibold flex item-center">
                            <h3>{data ? 'Data Panen' : 'Data Panen'}</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <div className={`${getStatusStyle()} font-medium px-2 md:px-8 py-2 md:py-4 text-xs md:text-sms flex items-center justify-between`}>
                        <p>Status Approval</p>
                        <p className="italic font-bold capitalize">Disetujui</p>
                    </div>
                    <div className="p-5 md:p-8 space-y-6">
                        <div>
                            <div className="flex items-center justify-between">
                                <h2 className='text-base text-lightcayn font-bold'>Informasi Panen</h2>
                            </div>
                            <table className="font-medium text-sms md:text-sm mt-2.5">
                                <tbody>
                                    <tr>
                                        <td className='text-gray-500 pb-1 w-[10rem] md:w-52'>Komoditas</td>
                                        <td>{data?.commodity_name_var}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Nama Lahan</td>
                                        <td>[{data.land_code_var}] {data?.land_name_var}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Periode Tanam</td>
                                        <td>Periode {data.period_plant_txt}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Hasil Panen (Kg)</td>
                                        <td>{data.harvest_flo}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Tanggal Panen</td>
                                        <td>{dayjs(data.harvest_on_dte).format('DD/MM/YYYY')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <h2 className='text-base text-lightcayn font-bold'>Hasil Panen Penjemuran</h2>
                            <table className="font-medium text-sms md:text-sm mt-2.5">
                                <tbody>
                                    <tr>
                                        <td className='text-gray-500 pb-1 w-48 md:w-52'>Tanggal Penjemuran</td>
                                        <td>{dayjs(data.harvest_drying_dte).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Hasil Penjemuran ( Kg )</td>
                                        <td>{data.harvest_milling_flo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <h2 className='text-base text-lightcayn font-bold'>Hasil Panen Penggilingan</h2>
                            <table className="font-medium text-sms md:text-sm mt-2.5">
                                <tbody>
                                    <tr>
                                        <td className='text-gray-500 pb-1 w-48 md:w-52'>Tanggal Penggilingan</td>
                                        <td>{dayjs(data.harvest_milling_dte).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-gray-500 pb-1'>Hasil Penggilingan ( Kg )</td>
                                        <td>{data.harvest_drying_flo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='mt-6'>
                            <h2 className='text-base text-lightcayn font-bold'>Dokumentasi Panen</h2>
                            <div className='grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-4 2xl:gap-5 mt-4'>
                                {data.document_txt.length > 0 && data.document_txt.map((url: string, key: number) =>
                                    <LazyLoadImage effect='blur' alt='' src={url} className='rounded-lg shadow-md h-48 object-cover' key={key} />
                                )}
                                {/* <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' />
                                <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' />
                                <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' />
                                <LazyLoadImage effect='blur' alt='' src={gandum} className='rounded-lg shadow-md h-48 object-cover' /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalFormPanen