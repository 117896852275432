import axios from 'axios'
import { baseURL } from './common-api'

export interface IProductList {
    id_seq?: number,
    id_product_var: string,
    product_name_var: string,
    product_type_int: number,
    product_name_type_var? : string,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}

export interface IEntryProduct {
    id_seq?: number,
    time_on_dte :  string,
    unique_code_var? : string,
    id_product_var? : string,
    product_name_var? : string,
    product_type_int?: number,
    quantity_int : number,
    measure_var : string,
    price_int : number,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}

export interface IMixingProduct {
    id_seq?: number,
    product_name_var? : string,
    id_product_var : string,
    quantity_int : number ,
    measure_var : string,
    qr_code_var: string,
    combination_json : any,
    product_type_int?: number,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}

export interface IExitProduct {
    id_seq?: number,
    time_on_dte :  string,
    id_product_var? : string,
    product_name_var? : string,
    product_type_int?: number,
    quantity_int : number,
    measure_var : string,
    price_int : number,
    unique_code_var?: string,
    customer_name_var? : string,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}


export interface IMonitoringProduct {
    id_seq?: number,
    id_product_var: string,
    product_name_var: string,
    product_type_int: number,
    product_name_type_var? : string,
    quantity_int : number,
    measure_var : string,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}



export class ProductListApi {
    static getAllProductList = async () => {
        try {
            const data = await axios.get(baseURL + '/api/daftar-product/get-all', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static getOneProductList = async (idSeq: number) => {
        try {
            const data = await axios.get(baseURL + '/api/daftar-product/get-one?id_seq=' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static createNewProductList = async (payload: IProductList) => {
        try {
            const data = await axios.post(baseURL + '/api/daftar-product/create', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static updateProductList = async (idSeq: number, payload: IProductList) => {
        try {
            const data = await axios.patch(baseURL + '/api/daftar-product/update/' + idSeq, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static deleteProductList = async (idSeq: number) => {
        try {
            const data = await axios.delete(baseURL + '/api/daftar-product/delete/' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
}


export class EntryProductApi {
    static getAll = async () => {
        try {
            const data = await axios.get(baseURL + '/api/entry-product/get-all', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static getOne = async (idSeq: number) => {
        try {
            const data = await axios.get(baseURL + '/api/entry-product/get-one?id_seq=' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static createNew = async (payload: IEntryProduct) => {
        try {
            const data = await axios.post(baseURL + '/api/entry-product/create', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static update = async (idSeq: number, payload: IEntryProduct) => {
        try {
            const data = await axios.patch(baseURL + '/api/entry-product/update/' + idSeq, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static delete = async (idSeq: number) => {
        try {
            const data = await axios.delete(baseURL + '/api/entry-product/delete/' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
}


export class MixingProductApi {
    static getAll = async () => {
        try {
            const data = await axios.get(baseURL + '/api/combination-product/get-all', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static getOne = async (idSeq: number) => {
        try {
            const data = await axios.get(baseURL + '/api/combination-product/get-one?id_seq=' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static createNew = async (payload: IMixingProduct) => {
        try {
            const data = await axios.post(baseURL + '/api/combination-product/create', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static update = async (idSeq: number, payload: IMixingProduct) => {
        try {
            const data = await axios.patch(baseURL + '/api/combination-product/update/' + idSeq, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static delete = async (idSeq: number) => {
        try {
            const data = await axios.delete(baseURL + '/api/combination-product/delete/' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
}


export class ExitProductApi {
    static getAll = async () => {
        try {
            const data = await axios.get(baseURL + '/api/exit-product/get-all', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static getOne = async (idSeq: number) => {
        try {
            const data = await axios.get(baseURL + '/api/exit-product/get-one?id_seq=' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static createNew = async (payload: IExitProduct) => {
        try {
            const data = await axios.post(baseURL + '/api/exit-product/create', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static update = async (idSeq: number, payload: IExitProduct) => {
        try {
            const data = await axios.patch(baseURL + '/api/exit-product/update/' + idSeq, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static delete = async (idSeq: number) => {
        try {
            const data = await axios.delete(baseURL + '/api/exit-product/delete/' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
}


export class MonitoringProductApi {
    static getAll = async () => {
        try {
            const data = await axios.get(baseURL + '/api/monitoring-product/get-all', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    
    static getOne = async (idSeq: number) => {
        try {
            const data = await axios.get(baseURL + '/api/monitoring-product/get-one?id_seq=' + idSeq, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
}


export const dropdownProductType = async () => {
    try {
        const data = await axios.get(baseURL + '/api/daftar-product/dropdown-type', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const dropdownProductListByType = async (productTypeId: number) => {
    try {
        const data = await axios.get(baseURL + '/api/entry-product/dropdown-product?product_type_id_int=' + productTypeId, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const dropdownProductList = async () => {
    try {
        const data = await axios.get(baseURL + '/api/combination-product/dropdown-product', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const dropdownUniqueCode = async () => {
    try {
        const data = await axios.get(baseURL + '/api/combination-product/dropdown-uniquecode', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}

export const getQRUrlContentCombination = async (qrCode: string) => {
    try {
        const data = await axios.get(baseURL + '/api/content/qr-product-detail?qr_code_var=' + qrCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}


export const getQRUrlContent = async (uniqueCode: string) => {
    try {
        const data = await axios.get(baseURL + '/api/content/qr-detail?unique_code_var=' + uniqueCode, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if (error.response) return error.response
        else return JSON.parse(JSON.stringify(error))
    }
}