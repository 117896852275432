import axios from "axios"
import { baseURL } from "./common-api"

export interface IActivity {
    id_seq: number,
    time_calender_dte?: string,
    time_txt: string,
    activity_txt: string,
    material_txt: string,
    dose_txt: string,
    name_planting_var?: string,
    jumlah_txt?: number,
    satuan_txt?: string,
    land_code_var?: string,
    commodity_name_var?: string,
    start_planting_dtm?: string,
    user_id_int?: number,
    period_plant_txt?: string,
    created_by_var?: string,
    created_on_dtm?: string,
    updated_by_var?: string,
    updated_on_dtm?: string,
}

export interface IObservartion {
    id_seq: number,
    time_calender_dte: string,
    user_id_int : number,
    land_code_var : string,
    period_plant_txt : string,
    jumlah_txt:	string,
    satuan_txt:	string,
    leaf_condition_txt : string,
    land_condition_txt : string,
    watering_condition_txt : string,
    puppies_condition_txt : string,
    grain_condition_txt : string,
    hama_txt : string,
    example_observation_txt : string,
    created_by_var : string,
    updated_by_var: string,
}

export interface IDocumentation {
    id_seq: number,
    created_by_var: string,
    created_on_dtm: string
    document_txt: string[]
    time_calender_dte: string,
    time_txt: string,
    updated_by_var: string
    updated_on_dtm: string
}

export interface IRating {
    id_seq: number,
    user_id_int : number,
    land_code_var : string,
    period_plant_txt : string,
    rating_txt: string,
    suggest_txt: string,
    time_txt: string,
    created_by_var: string,
    created_on_dtm: string
    time_calender_dte: string,
    updated_by_var: string
    updated_on_dtm: string
}

class TaskApi {
    static getData = async () => {
        try {
            const data = await axios.get(baseURL + '/api/cultivation-task/get-data', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static getLandTask = async (userID: number, landCode: string, period: any) => {
        try {
            const payload = {
                user_id_int: userID,
                land_code_var: landCode,
                period_plant_txt: period
            }

            const data = await axios.post(baseURL + '/api/cultivation-task/get-one', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    static getLeafCondition = async () => {
        try {
            const data = await axios.get(baseURL + '/api/cultivation-task/dropdownLeafCondition', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    static getLandCondition = async () => {
        try {
            const data = await axios.get(baseURL + '/api/cultivation-task/dropdownLandCondition', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    static getPuppiesCondition = async () => {
        try {
            const data = await axios.get(baseURL + '/api/cultivation-task/dropdownPuppiesCondition', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    static getWateringCondition = async () => {
        try {
            const data = await axios.get(baseURL + '/api/cultivation-task/dropdownWateringCondition', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }
    static getGrainCondition = async () => {
        try {
            const data = await axios.get(baseURL + '/api/cultivation-task/dropdownGrainCondition', {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if (error.response) return error.response
            else return JSON.parse(JSON.stringify(error))
        }
    }

    
    static dropdownPeriodByLandCode = async (landCode: string) => {
        try {
            const data = await axios.get(baseURL + '/api/cultivation-task/dropdownPeriod?land_code_var='+ landCode, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static createNewActivity = async (payload: IActivity) => {
        try {
            const data = await axios.post(baseURL + '/api/cultivation-task/create-activity', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static updateActivity = async (id: number, payload: IActivity) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/update-activity/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static deleteActivity = async (id: number, payload: any) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/delete-activity/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static createObservation = async (payload: IObservartion) => {
        try {
            const data = await axios.post(baseURL + '/api/cultivation-task/create-observation', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static updateObservation = async (id: number, payload: IObservartion) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/update-observation/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static deleteObservation = async (id: number, payload: any) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/delete-observation/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static addDocumentation = async (payload: FormData) => {
        try {
            const data = await axios.post(baseURL + '/api/cultivation-task/create-documentation', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static updateDocumentation = async (id: number, payload: FormData) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/update-documentation/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static deleteDocumentation = async (id: number, payload: any) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/delete-documentation/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static addRating = async (payload: IRating) => {
        try {
            const data = await axios.post(baseURL + '/api/cultivation-task/create-rating', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static updateRating = async (id: number, payload: IRating) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/update-rating/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static deleteRating = async (id: number, payload: any) => {
        try {
            const data = await axios.patch(baseURL + '/api/cultivation-task/delete-rating/' + id, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
}

export default TaskApi