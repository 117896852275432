import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import farmerIcon from '../../assets/img/farmer.png'

import ReactApexChart from "react-apexcharts"
import { ApexOptions } from 'apexcharts';
import useMobileView from '../../hooks/useMobileView';
import dayjs from 'dayjs';
import { GlobalContext } from '../../context/GlobalProvider';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../reduxs/action/actions';
import { useLocation } from 'react-router-dom';
import { IRecentPage } from '../../context/ContextInterface';
import { getActvitiesDashboard, getDashboard } from '../../api/common-api';
import Loader from '../../components/modal/Loader';
import { toast } from 'react-toastify';
import Helper from '../../helpers/helper';
import useDropdownFarmer from '../../hooks/useDropdownFarmer';
import useDropdownLand, { useDropdownPeriod } from '../../hooks/useDropdownLand';
import Select from 'react-select'
import { SelectCustomStyles } from '../admin/TaskManagement';
import TableMax from '../../components/tables/TableMax';


const TITLE_BAR = 'Dashboard'

type Props = {};

const DashbboardAdmin: React.FC<Props> = () => {
    const [data, setData] = useState<any>(null)
    const [loader, showLoader] = useState(false)
    const [activities, setActivities] = useState<any[]>([])
    const [selectedLand, setSelectedLand] = useState<any>(null)
    const [selectedFarmer, setSelectedFarmer] = useState<any>(null)
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null)

    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { isMobile } = useMobileView()
    const { userStorage, dispatchCalPage } = useContext(GlobalContext)

    const { dataFarmer, loadingFarmer } = useDropdownFarmer()
    const { dataLand, loadingLand } = useDropdownLand(selectedFarmer?.id_seq || 0)
    const { dataPeriod, loadingPeriod } = useDropdownPeriod(selectedLand?.land_code_var || '')

    var optionsPieChar: ApexOptions = {
        chart: {
            type: "donut"
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: { show: false },
                    size: '60%'
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        labels: ['RF', 'Non RF'],
        // colors: ["#436B94", "#556ee6", "#cad1e0"],
        colors: ["#556ee6", "#436B94"],
        legend: { show: !1 },
        stroke: {
            width: 0
        }
    }

    var optionsFunding: ApexOptions = {
        chart: {
            type: "donut"
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: { show: false },
                    size: '60%'
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        labels: ['Realisasi Pendanaan', 'Sisa Pendanaan'],
        colors: ["#436B94", "#556ee6"],
        // colors: ["#436B94", '#cad1e0'],
        legend: { show: !1 },
        stroke: {
            width: 0
        }
    }

    var optionLand: ApexOptions = {
        chart: {
            type: 'donut'
        },
        plotOptions: {
            pie: {
                donut: {
                    // labels: { show: false },
                    size: '60%'
                }
            }
        },
        labels: ['Lahan Aktif', 'Lahan Non Aktif'],
        dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //     return val + "%"
            // },
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            },
        },
        legend: {
            position: 'bottom'
        }
    }

    var optionLuas: ApexOptions = {
        chart: {
            type: 'donut'
        },
        plotOptions: {
            pie: {
                donut: {
                    // labels: { show: false },
                    size: '60%'
                }
            }
        },
        labels: ['Luas Aktif', 'Luas Non Aktif'],
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return Number(opts.w.config.series[opts.seriesIndex]).toFixed(2)
            },
        },
        legend: {
            position: 'bottom'
        }
    }


    useEffect(() => {
        const payload: IRecentPage = {
            id: new Date().getTime(),
            title: TITLE_BAR,
            path: pathname,
            url: window.location.href,
        };

        dispatchCalPage({ type: 'push', payload })
        dispatch({ type: ActionType.SET_TITLE_BAR, value: TITLE_BAR })
    }, [dispatch, dispatchCalPage, pathname])

    useEffect(() => {
        (
            async function fetchDataDashboard() {
                showLoader(true)
                const res = await getDashboard()

                console.log("DASHBOARD ==> ", res)
                if (res.data && res.data.code === 0) {
                    setData(res.data.data[0])
                    showLoader(false)
                } else {
                    showLoader(false)
                    toast.error(res.data?.message || res.status + ' ' + res.message || 'Connection timeout')
                }
            }
        )()
    }, [])

    const fetchActivities = async () => {
        (
            async function fetchDataDashboard() {
                showLoader(true)
                const res = await getActvitiesDashboard(selectedFarmer?.id_seq || 'ALL', selectedLand?.land_code_var || 'ALL', selectedPeriod?.period_plant_txt || 'ALL')

                console.log("DASHBOARD ACTIVITIES ==> ", res)
                if (res.data && res.data.code === 0) {
                    setActivities(res.data.data)
                    showLoader(false)
                } else {
                    showLoader(false)
                    // toast.error(res.data?.message || res.status + ' ' + res.message || 'Connection timeout')
                }
            }
        )()
    }

    useEffect(() => {
        fetchActivities()
        // eslint-disable-next-line
    }, [])

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }: any) => <div className='text-left pl-4'>{row.index + 1}.</div>,
            width: 10,
        },
        {
            Header: 'Aktivitas',
            accessor: 'activity_txt',
            width: 300,
        },
        {
            Header: 'Waktu',
            accessor: 'time_txt',
            width: 60,
        },
        {
            Header: 'Tanggal',
            accessor: 'time_calender_dte',
            width: 90,
            Cell: ({value}: any) => {
                return dayjs(value).format('DD/MM/YYYY')
            }
        },
        {
            Header: 'Komoditas',
            accessor: 'commodity_name',
            width: 100,
        },
        {
            Header: 'Metode Tanam',
            accessor: 'name_planting',
            width: 110,
        },
        {
            Header: 'Lahan',
            accessor: 'land_code_var',
            width: 125,
            Cell: ({value, row}: any) => {
                const data = row.original
                return <span>[{value}] {data.land_name_var}</span>
            }
        },
        {
            Header: 'Periode',
            accessor: 'period_plant_txt',
            width: 50,
        },
        {
            Header: 'Petani',
            accessor: 'fullname_var',
            width: 150,
        },
        {
            Header: 'Pendamping',
            accessor: 'field_assistant_name_var',
            width: 125,
        },
    ]

    return (
        <>
            <div className='flex flex-col md:flex-row space-y-5 md:space-y-0 font-medium text-sm'>
                <div className='w-full space-y-5 p-5'>
                    {/* HEADER */}
                    <div className='w-full relative py-6 pl-[6.5rem] md:pl-40 bg-white rounded-2xl shadow-lg mt-2 flex flex-col justify-center'>
                        <h1 className='font-bold text-base md:text-2xl mb-1'>Hallo, {userStorage.fullname_var}..</h1>
                        <p>Selamat datang di Dashboard Farm Management System</p>
                        {isMobile ?
                            <LazyLoadImage className='absolute top-3 left-3 w-20' effect='black-and-white' alt='' src={farmerIcon} />
                            :
                            <LazyLoadImage className='absolute bottom-0 left-6 w-28' effect='black-and-white' alt='' src={farmerIcon} />
                        }
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5'>
                        <Card title='Total Project' value={data?.total_project_int || '0'} color='blue' />
                        <Card title='Total Petani' value={data?.total_farmer_int || '0'} color='green' icon='fa-solid fa-person-digging' />
                        <Card title='Total FA' value={data?.total_fa_int || '0'} color='yellow' icon='fa-solid fa-user-group' />
                    </div>

                    <div className='flex flex-col-reverse md:flex-row md:space-x-5 space-y-5 md:space-y-0'>

                        <div className='w-full md:w-1/3 bg-white rounded-2xl shadow-lg p-6 md:p-5 2xl:p-7 flex flex-col'>
                            <h3 className='font-bold text-base mb-4'>Detail Pendapatan</h3>
                            <div className='flex border-b pb-4'>
                                <i className="fa-solid fa-money-bill-wave text-xl w-6 2xl:w-7 text-gray-400 mr-4 2xl:mr-5"></i>
                                <div>
                                    <p className='text-gray-500'>Total Pendapatan</p>
                                    <p className='font-bold text-2xl 2xl:text-3xl text-green-700'>Rp{Helper.amount(data?.total_gross_revenue_int)}</p>
                                </div>
                            </div>
                            <div className='flex mt-5'>
                                <i className="fa-solid fa-leaf text-xl w-6 2xl:w-8 text-gray-400 mr-4 2xl:mr-5"></i>
                                <div>
                                    <p>Beras Campuran</p>
                                    <p className='font-bold text-base 2xl:text-xl'>Rp{Helper.amount(data?.total_gross_beras_campuran_int)}</p>
                                </div>
                            </div>
                            <div className='flex mt-4 2xl:mt-5'>
                                <i className="fa-brands fa-pagelines text-xl w-6 2xl:w-8 text-gray-400 mr-4 2xl:mr-5"></i>
                                <div>
                                    <p>Beras</p>
                                    <p className='font-bold text-base 2xl:text-xl'>Rp{Helper.amount(data?.total_gross_beras_int)}</p>
                                </div>
                            </div>
                            <div className='flex mt-4 2xl:mt-5'>
                                <i className="fa-brands fa-envira text-xl w-6 2xl:w-8 text-gray-400 mr-4 2xl:mr-5"></i>
                                <div>
                                    <p>Saprodi</p>
                                    <p className='font-bold text-base 2xl:text-xl'>Rp{Helper.amount(data?.total_gross_saprodi_int)}</p>
                                </div>
                            </div>
                        </div>

                        <div className='w-full md:w-2/3 pb-7 md:pb-0'>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <Card title='Total Stock Beras' value={`${data?.stock_beras_int || '0'} Kg`} color='slate' icon='fa-brands fa-pagelines' addingStyleValue='text-lg' addingStyleTitle='text-xs' />
                                <Card title='Total Panen' value={`${data?.total_panen_flo || '0'} Kg`} color='red' icon='fa-solid fa-leaf' addingStyleValue='text-lg' />
                            </div>

                            <div className='mt-5 2xl:mt-7'>
                                <h3 className='text-lg md:text-base 2xl:text-lg font-semibold'>Data Lahan</h3>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-2 2xl:mt-4'>
                                    <div className='bg-white rounded-2xl shadow-lg p-1'>
                                        <ReactApexChart
                                            options={optionLand}
                                            series={[data?.total_land_active_int, (data?.total_land_int - data?.total_land_active_int)]}
                                            type='donut'
                                        />
                                        <div className='flex items-center justify-between py-3 md:py-1 2xl:py-3 px-14 md:px-4 2xl:px-14 text-xs'>
                                            <div className='flex items-center'>
                                                <span className='w-3.5 h-3.5 rounded-full bg-[#FEB019] mr-3'></span>
                                                <p>Total Keseluruhan</p>
                                            </div>
                                            <p className='font-bold'>{data?.total_land_int}</p>
                                        </div>
                                    </div>
                                    <div className='bg-white rounded-2xl shadow-lg p-1'>
                                        <ReactApexChart
                                            options={optionLuas}
                                            series={[data?.total_area_active_flo, data?.total_area_flo - data?.total_area_active_flo]}
                                            type='donut'
                                        />
                                        <div className='flex items-center justify-between py-3 md:py-1 2xl:py-3 px-14 md:px-4 2xl:px-14 text-xs'>
                                            <div className='flex items-center'>
                                                <span className='w-3.5 h-3.5 rounded-full bg-[#FEB019] mr-3'></span>
                                                <p>Total Keseluruhan</p>
                                            </div>
                                            <p className='font-bold'>{data?.total_area_flo}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* RIGHT SIDE */}
                <div className='w-full md:w-[19.5rem] 2xl:w-96 bg-white shadow-lg rounded-2xl'>
                    <div className='bg-darkcayn flex item-center justify-center p-5'>
                        <i className="fa-solid fa-cloud-sun flex justify-center items-center text-4xl text-yellow-300"></i>
                        <div className='text-white ml-6 md:ml-3 2xl:ml-5'>
                            <p className='text-4xl md:text-3xl 2xl:text-4xl font-bold'>32&deg;C</p>
                            <p className='text-sm md:text-[11.1px] 2xl:text-sm'>{dayjs().format('dddd, DD MMMM YYYY')}</p>
                        </div>
                    </div>
                    <div className='p-5'>
                        <div>
                            <h3 className='font-bold text-base'>Sumber Dana</h3>
                            <ReactApexChart
                                options={optionsPieChar}
                                series={[data?.total_rf_int, data?.total_nonrf_int]}
                                type='donut'
                            />
                            <div className='space-y-1.5 mb-6'>
                                <div className='flex items-center justify-between text-sm md:text-xs 2xl:text-sm'>
                                    <div className='flex items-center'>
                                        <span className='w-4 h-4 rounded-full bg-blue-500 mr-3'></span>
                                        <p>RF</p>
                                    </div>
                                    {/* <p className='font-bold'>({data?.total_rf_int}) {(data?.total_rf_int * 100 / (data?.total_rf_int + data?.total_nonrf_int)).toFixed(0)}%</p> */}
                                    <p className='font-bold text-sm'>{data?.total_rf_int}</p>
                                </div>
                                <div className='flex items-center justify-between text-sm md:text-xs 2xl:text-sm'>
                                    <div className='flex items-center'>
                                        <span className='w-4 h-4 rounded-full bg-[#436B94] mr-3'></span>
                                        <p>Non RF</p>
                                    </div>
                                    {/* <p className='font-bold text-sm'>({data?.total_nonrf_int}) {(data?.total_nonrf_int * 100 / (data?.total_rf_int + data?.total_nonrf_int)).toFixed(0)}%</p> */}
                                    <p className='font-bold text-sm'>{data?.total_nonrf_int}</p>
                                </div>
                                <div className='flex items-center justify-between text-sm md:text-xs 2xl:text-sm'>
                                    <div className='flex items-center'>
                                        <span className='w-4 h-4 rounded-full bg-[#cad1e0] mr-3'></span>
                                        <p>Total Keseluruhan</p>
                                    </div>
                                    <p className='font-bold text-sm'>{data?.total_rf_int + data?.total_nonrf_int}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className='font-bold text-base'>Realisasi Pendanaan</h3>
                            <ReactApexChart
                                options={optionsFunding}
                                series={[data?.total_realisasi_funding_int, data?.total_funding_int - data?.total_realisasi_funding_int]}
                                type='donut'
                            />
                            <div className='space-y-2 mx-0 md:-mx-2 2xl:mx-0'>
                                <div className='flex items-center justify-between text-sm md:text-xs 2xl:text-sm'>
                                    <div className='flex items-center'>
                                        <span className='w-3.5 h-3.5 rounded-full bg-[#cad1e0] mr-1.5'></span>
                                        <p className='text-sms md:text-xsm 2xl:text-sms'>Total Pendanaan</p>
                                    </div>
                                    <p className='font-bold'>Rp{Helper.amount(data?.total_funding_int)}</p>
                                </div>
                                <div className='flex items-center justify-between text-sm md:text-xs 2xl:text-sm'>
                                    <div className='flex items-center'>
                                        <span className='w-3.5 h-3.5 rounded-full bg-[#436B94] mr-1.5'></span>
                                        <p className='text-sms md:text-xsm 2xl:text-sms'>Realisasi Pendanaan</p>
                                    </div>
                                    <p className='font-bold'>Rp{Helper.amount(data?.total_realisasi_funding_int)}</p>
                                </div>
                                <div className='flex items-center justify-between text-sm md:text-xs 2xl:text-sm'>
                                    <div className='flex items-center'>
                                        <span className='w-3.5 h-3.5 rounded-full bg-blue-500 mr-1.5'></span>
                                        <p className='text-sms md:text-xsm 2xl:text-sms'>Sisa Pendanaan</p>
                                    </div>
                                    <p className='font-bold'>Rp{Helper.amount(data?.total_funding_int - data?.total_realisasi_funding_int)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='p-5'>
                <div className='bg-white p-5 shadow-lg rounded-2xl'>
                    <h3 className='font-bold text-base mb-4'>Aktivitas Terbaru</h3>
                    <div className='w-full md:w-max flex flex-col md:flex-row items-center justify-between md:justify-center md:space-x-3 space-y-3 md:space-y-0 mb-5 md:mb-4 2xl:mb-5'>
                        <Select name='farmer' styles={SelectCustomStyles} options={dataFarmer} onChange={setSelectedFarmer} isClearable placeholder='- Pilih Petani -' className='w-full md:w-[200px]' isLoading={loadingFarmer}
                            getOptionValue={(option: any) => option['id_seq']}
                            getOptionLabel={(option: any) => option['fullname_var']}
                        />
                        <Select name='land' styles={SelectCustomStyles} options={dataLand} onChange={setSelectedLand} isClearable placeholder='- Pilih Lahan -' className='w-full md:w-[200px] 2xl:md:w-[300px]' isLoading={loadingLand}
                            getOptionValue={(option: any) => option['land_code_var']}
                            getOptionLabel={(option: any) => `[${option['land_code_var']}] ${option['land_name_var']}`}
                        />
                        <Select name='period' styles={SelectCustomStyles} options={dataPeriod} onChange={setSelectedPeriod} isClearable placeholder='- Pilih Periode -' className='w-full md:w-[175px] 2xl:md:w-[200px]' isLoading={loadingPeriod}
                            getOptionValue={(option: any) => option['period_plant_txt']}
                            getOptionLabel={(option: any) => option['period_plant_txt']}
                        />
                        <button type='submit' className='w-full md:w-max bg-blue-600 hover:bg-blue-500 text-white px-6 py-3 rounded-lg text-sm' onClick={fetchActivities}>Terapkan</button>
                    </div>

                    <TableMax dataTable={activities} columnTable={columns} />
                </div>
            </div>

            {loader && <Loader />}
        </>
    );
}


const Card = ({ title, value, icon, color, addingStyleTitle, addingStyleValue }: any) => {
    return (
        <div className='flex items-center p-6 2xl:p-8 bg-white rounded-2xl shadow-lg'>
            <span className={`bg-${color}-100 text-${color}-500 w-12 h-12 flex justify-center items-center rounded-md text-lg mr-6`}>
                <i className={icon || "fa-solid fa-map-location"}></i>
            </span>
            <div>
                <h3 className={`font-bold ${addingStyleValue || 'text-2xl'}`}>{value}</h3>
                <p className={`${addingStyleTitle}`}>{title}</p>
            </div>
        </div>
    )
}


export default DashbboardAdmin;