import { useEffect, useState } from 'react';
import TaskApi from '../api/task-api';

export default function useDropdownPengamtan() {
    const [dataLeaf, setDataLeaft] = useState<any[]>([])
    const [dataLand, setDataLand] = useState<any[]>([])
    const [dataPuppies, setDataPuppies] = useState<any[]>([])
    const [dataWatering, setDataWatering] = useState<any[]>([])
    const [dataGrain, setDataGrain] = useState<any[]>([])
    
    const [loadingLeaf, setLoadingLeaf] = useState(false)
    const [errorLeaf, setErrorLeaf] = useState(null)
    const [loadingLand, setLoadingLand] = useState(false)
    const [errorLand, setErrorLand] = useState(null)
    const [loadingPuppies, setLoadingPuppies] = useState(false)
    const [errorPuppies, setErrorPuppies] = useState(null)
    const [loadingWatering, setLoadingWatering] = useState(false)
    const [errorWatering, setErrorWatering] = useState(null)
    const [loadingGrain, setLoadingGrain] = useState(false)
    const [errorGrain, setErrorGrain] = useState(null)

    useEffect(() => {
        async function fetchDropdownLeaf() {
            setLoadingLeaf(true)
            const res = await TaskApi.getLeafCondition()

            console.log("Fetching DropdownLeaf :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataLeaft(res.data.data)
                }else{
                    setErrorLeaf(res.data.message)
                }
            }else{
                setErrorLeaf(res.message)
            }
            setLoadingLeaf(false)
        }
        async function fetchDropdownLand() {
            setLoadingLand(true)
            const res = await TaskApi.getLandCondition()

            console.log("Fetching DropdownLand :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataLand(res.data.data)
                }else{
                    setErrorLand(res.data.message)
                }
            }else{
                setErrorLand(res.message)
            }
            setLoadingLand(false)
        }
        async function fetchDropdownPuppies() {
            setLoadingPuppies(true)
            const res = await TaskApi.getPuppiesCondition()

            console.log("Fetching DropdownPuppies :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataPuppies(res.data.data)
                }else{
                    setErrorPuppies(res.data.message)
                }
            }else{
                setErrorPuppies(res.message)
            }
            setLoadingPuppies(false)
        }
        async function fetchDropdownWatering() {
            setLoadingWatering(true)
            const res = await TaskApi.getWateringCondition()

            console.log("Fetching DropdownWatering :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataWatering(res.data.data)
                }else{
                    setErrorWatering(res.data.message)
                }
            }else{
                setErrorWatering(res.message)
            }
            setLoadingWatering(false)
        }
        async function fetchDropdownGrain() {
            setLoadingGrain(true)
            const res = await TaskApi.getGrainCondition()

            console.log("Fetching DropdownGrain :", res)
            if (res.data) {
                if (res.data.code === 0) {
                    setDataGrain(res.data.data)
                }else{
                    setErrorGrain(res.data.message)
                }
            }else{
                setErrorGrain(res.message)
            }
            setLoadingGrain(false)
        }

        fetchDropdownLand()
        fetchDropdownLeaf()
        fetchDropdownPuppies()
        fetchDropdownWatering()
        fetchDropdownGrain()
    }, [])
    

    return {
        dataLeaf, loadingLeaf, errorLeaf, 
        dataLand, errorLand, loadingLand,
        dataPuppies, loadingPuppies, errorPuppies,
        dataWatering, loadingWatering, errorWatering,
        dataGrain, loadingGrain, errorGrain
    }
}