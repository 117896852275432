import axios from "axios";
import { baseURL } from "./common-api";

export interface IBudgetPlan {
  id_seq?: number;
  user_id_int: number;
  fullname_var?: string;
  land_code_var: string;
  land_name_var?: string;
  commodity_name_var: string;
  period_plant_txt: string;
  budget_plan_var: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export interface IBudgetDetail {
  id_seq?: number;
  budget_id_int: number;
  activity_txt: string;
  category_var: string;
  area_var: string;
  quantity_var: string;
  satuan_var: string;
  price_var: string;
  total_price_var: string;
  document_txt?: any;
  images: any;
  status_int: number;
  status_name_var: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export interface IBudgetCategory {
  id_seq?: number;
  category_var: string;
  activity_txt: string;
  commodity_name_var: string;
  created_by_var?: string;
  created_on_dtm?: string;
  updated_by_var?: string;
  updated_on_dtm?: string;
}

export class BudgetPlanApi {
  static getAll = async () => {
    try {
      const data = await axios.get(baseURL + "/api/budget-plan/get-all", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static getOne = async (idSeq: number) => {
    try {
      const data = await axios.get(baseURL + "/api/budget-plan/get-one?id_seq=" + idSeq, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static createNew = async (payload: IBudgetPlan) => {
    try {
      const data = await axios.post(baseURL + "/api/budget-plan/create", payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static update = async (idSeq: number, payload: IBudgetPlan) => {
    try {
      const data = await axios.patch(baseURL + "/api/budget-plan/update/" + idSeq, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static delete = async (idSeq: number) => {
    try {
      const data = await axios.delete(baseURL + "/api/budget-plan/delete/" + idSeq, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };
}

export class BudgetDetailApi {
  static getAll = async () => {
    try {
      const data = await axios.get(baseURL + "/api/budget-detail/get-all", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static getOne = async (idSeq: number) => {
    try {
      const data = await axios.get(baseURL + "/api/budget-detail/get-one?id_seq=" + idSeq, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static createNew = async (payload: FormData) => {
    try {
      const data = await axios.post(baseURL + "/api/budget-detail/create", payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static update = async (idSeq: number, payload: FormData) => {
    try {
      const data = await axios.patch(baseURL + "/api/budget-detail/update/" + idSeq, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static delete = async (idSeq: number) => {
    try {
      const data = await axios.delete(baseURL + "/api/budget-detail/delete/" + idSeq, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static request = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(baseURL + "/api/budget-detail/approval/" + idSeq, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static approval = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(baseURL + "/api/budget-detail/approved/" + idSeq, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static reject = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(baseURL + "/api/budget-detail/rejected/" + idSeq, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static draft = async (idSeq: number, payload: any) => {
    try {
      const data = await axios.patch(baseURL + "/api/budget-detail/draft/" + idSeq, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static getDropdownActivity = async (category_var: string) => {
    try {
      const data = await axios.get(baseURL + "/api/budget-detail/dropdownActivity?category_var=" + category_var, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static getDropdownCategory = async () => {
    try {
      const data = await axios.get(baseURL + "/api/budget-detail/dropdownCategory", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };
}

export class BudgetCategoryApi {
  static getAll = async () => {
    try {
      const data = await axios.get(baseURL + "/api/category-ref/get-all", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static getOne = async (idSeq: number) => {
    try {
      const data = await axios.get(baseURL + "/api/category-ref/get-one?id_seq=" + idSeq, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static createNew = async (payload: IBudgetCategory) => {
    try {
      const data = await axios.post(baseURL + "/api/category-ref/create", payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static update = async (idSeq: number, payload: IBudgetCategory) => {
    try {
      const data = await axios.patch(baseURL + "/api/category-ref/update/" + idSeq, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };

  static delete = async (idSeq: number) => {
    try {
      const data = await axios.delete(baseURL + "/api/category-ref/delete/" + idSeq, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      if (error.response) return error.response;
      else return JSON.parse(JSON.stringify(error));
    }
  };
}
