import axios from "axios";

export const baseURL = 'http://153.92.209.174:2229'                    // Server PROD PANDAWA


// export const QRCODE_URL = 'http://localhost:1905/pandawa/qr-product-detail/'
export const QRCODE_URL = 'http://103.65.236.175/pandawa/qr-product-detail/'

const api = axios.create({
    baseURL: baseURL,
    headers: {
        authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
        Accept: "application/json",
        "Content-Type": "application/json"
    }
})

export const authLogin = async (payload) => {
    try {
        const data = await api.post('/api/login', payload)
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const authRegister = async (payload) => {
    try {
        const data = await api.post('/api/register', payload)
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const authValidateOTP = async (payload) => {
    try {
        const data = await api.post('/api/otp-validate', payload)
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getSidebarMenu = async (token, roleID) => {
    try {
        const payload = {
            "user_group_id_int" : roleID
         }
         
        const data = await axios.post(baseURL + '/api/login/menu', payload, {
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getDashboard = async () => {
    try {
        const data = await axios.get(baseURL + '/api/dashboard/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getActvitiesDashboard = async (userId, landCode, periodPlant) => {
    try {
        const data = await axios.get(baseURL + `/api/dashboard/get-activity?user_id_int=${userId}&land_code_var=${landCode}&period_plant_txt=${periodPlant}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("pandawa-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getProvince = async () => {
    try {
        const {data} = await axios.get(baseURL + '/api/location/prov-all')
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getCities = async (provID) => {
    try {
        const {data} = await axios.get(baseURL + '/api/location/city-prov?prov_id' + provID )
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getAllCities = async () => {
    try {
        const {data} = await axios.get(baseURL + '/api/location/city-all')
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getDistricts = async (cistyID) => {
    try {
        const {data} = await axios.get(baseURL + '/api/location/dis-city?city_id' + cistyID )
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getAllDistricts = async () => {
    try {
        const {data} = await axios.get(baseURL + '/api/location/dis-all')
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getAllLocation = async () => {
    try {
        const {data} = await axios.get(baseURL + '/api/location/all')
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export default api