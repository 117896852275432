import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { ICommodity } from "../../api/commodity-api";
import dayjs from "dayjs";
import Loader from "./Loader";
import useDropdownFarmer from "../../hooks/useDropdownFarmer";
import useDropdownLand from "../../hooks/useDropdownLand";
import useDropdownCommodity, { useDropdownPlantingMethod, useDropdownTemplateTask } from "../../hooks/useDropdownCommodity";
import { getFilename } from "../../helpers/helper";

export const SOURCE_FUNDING_LIST = ["RF", "NON RF"];

type Props = {
  data: ICommodity;
  dataUser?: any;
  onCancel: (e: React.MouseEvent) => any;
  onSubmit: (data: ICommodity) => void;
};

const ModalFormCultivation: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
  const [userID, setUserID] = useState(data ? data.user_id_int : 0);
  const [commodityName, setCommodityName] = useState(data ? data.commodity_name_var : "");
  const [plantingMethod, setPlantingMethod] = useState(data? data.planting_method_int : 0)

  const { dataFarmer, loadingFarmer } = useDropdownFarmer();
  const { dataLand, loadingLand } = useDropdownLand(userID); // eslint-disable-line
  const { dataCommodity, loadingCommodity } = useDropdownCommodity();
  const { dataPlanting } = useDropdownPlantingMethod(commodityName);
  const { dataTemplate } = useDropdownTemplateTask(commodityName, plantingMethod!)

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({ criteriaMode: "all" });

  const setdefaultValue = useCallback(
    (obj) => {
      Object.keys(obj).map((key) => {
        if (key === "start_planting_dtm") {
          return setValue(key, dayjs(obj[key]).format("YYYY-MM-DD"));
        } else if (key === "period_plant_txt") {
          // 2201
          let existPeriod: any = "";
          if (data.period_plant_txt?.length! > 2) {
            existPeriod = data.period_plant_txt?.substring(2, data.period_plant_txt.length);
          } else {
            existPeriod = data.period_plant_txt;
          }
          return setValue(key, existPeriod);
        } else {
          return setValue(key, obj[key], { shouldValidate: true });
        }
      });
    },
    [setValue, data?.period_plant_txt]
  );

  useEffect(() => {
    if (data) {
      setCommodityName(data.commodity_name_var);
      setPlantingMethod(data.planting_method_int)
      setUserID(data.user_id_int);
      setdefaultValue(data);
    }
  }, [data, setdefaultValue]);

  const onSubmitForm: SubmitHandler<ICommodity> = ({
    user_id_int,
    land_code_var,
    commodity_name_var,
    commodity_type_var,
    start_planting_dtm,
    period_plant_txt,
    harvest_prediction_int,
    planting_method_int,
    planting_phase_int,
    source_funding_var,
    file_template_txt
  }) => {
    // 2201
    let existPeriodYear: any = "";
    if (data && data.period_plant_txt?.length! > 2) {
      existPeriodYear = data.period_plant_txt?.substring(0, 2);
    } else {
      existPeriodYear = dayjs().format("YY");
    }

    const payload = {
      user_id_int,
      land_code_var,
      commodity_name_var: commodity_name_var.toUpperCase(),
      commodity_type_var,
      start_planting_dtm,
      period_plant_txt: existPeriodYear + period_plant_txt,
      // harvest_prediction_int,
      planting_method_int,
      source_funding_var,
      file_template_txt: file_template_txt.split('.').length > 0? file_template_txt.split('.')[0]: file_template_txt
      // planting_phase_int
    };

    console.log(payload);

    onSubmit(payload);
  };

  return (
    <>
      {!loadingFarmer && !loadingCommodity ? (
        <div className="modal-form">
          <div className="modal-form-outside" onClick={onCancel}></div>

          <div className="modal-form-content" style={{ maxHeight: "90vh" }}>
            <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
              <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                <h3>{data ? "Ubah Cultivation" : "Tambah Cultivation"}</h3>
              </div>
              <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white" onClick={onCancel}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
              <div className="space-y-5">
                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  <div className="w-full md:w-1/2">
                    <label htmlFor="user_id_int" className="label-form">
                      User
                    </label>
                    <select id="user_id_int" className="input-form" {...register("user_id_int", { required: "User is required" })} onChange={(e) => setUserID(Number(e.target.value))}>
                      <option value="">- Pilih User -</option>
                      {dataFarmer.map((item, key) => (
                        <option key={key} value={item.id_seq}>
                          {item.fullname_var}
                        </option>
                      ))}
                    </select>
                    <ErrorField errors={errors} name="user_id_int" />
                  </div>
                  {!loadingLand && (
                    <div className="w-full md:w-1/2">
                      <label htmlFor="land_code_var" className="label-form">
                        Lahan
                      </label>
                      <select id="land_code_var" className="input-form" {...register("land_code_var", { required: "Land code is required" })}>
                        <option value="">- Pilih Lahan -</option>
                        {dataLand!.map((item, key) => (
                          <option key={key} value={item.land_code_var}>
                            [{item.land_code_var}] {item.land_name_var}
                          </option>
                        ))}
                      </select>
                      <ErrorField errors={errors} name="land_code_var" />
                    </div>
                  )}
                </div>

                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  <div className="w-full">
                    <label htmlFor="commodity_name_var" className="label-form">
                      Nama Komoditas
                    </label>
                    <select id="commodity_name_var" className="input-form" {...register("commodity_name_var", { required: "Commodity is required" })} onChange={(e) => setCommodityName(e.target.value)} >
                      <option value="">- Pilih Komoditas -</option>
                      {dataCommodity!.map((item, key) => (
                        <option key={key} value={item.commodity_name_var}>
                          {item.commodity_name_var}
                        </option>
                      ))}
                    </select>
                    <ErrorField errors={errors} name="commodity_name_var" />
                  </div>
                  <div className="w-full">
                    <label htmlFor="commodity_type_var" className="label-form">
                      Tipe Komoditas
                    </label>
                    <input
                      id="commodity_type_var"
                      type="text"
                      className="input-form"
                      placeholder="C2 (Citrona 2 Agribun..."
                      {...register("commodity_type_var", {
                        required: "Commodity Type is required.",
                      })}
                    />
                    <ErrorField errors={errors} name="commodity_type_var" />
                  </div>
                </div>
                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  <div className="w-full">
                    <label htmlFor="start_planting_dtm" className="label-form">
                      Mulai Tanam
                    </label>
                    <input id="start_planting_dtm" type="date" className="input-form" {...register("start_planting_dtm", { required: "Start Planting is required." })} />
                    <ErrorField errors={errors} name="start_planting_dtm" />
                  </div>
                  <div className="w-full">
                    <label htmlFor="period_plant_txt" className="label-form">
                      Periode Tanam
                    </label>
                    <div className="flex item-center space-x-2">
                      <input className="input-form w-20 outline-none focus:outline-none focus:ring-0" defaultValue={new Date().getFullYear()} readOnly />
                      <input id="period_plant_txt" type="text" className="input-form" placeholder="01" {...register("period_plant_txt", { required: "Period Planting is required." })} />
                    </div>
                    <ErrorField errors={errors} name="period_plant_txt" />
                  </div>
                </div>

                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                  {data ? (
                    dataPlanting.length > 0 ? (
                      <div className="w-full">
                        <label htmlFor="planting_method_int" className="label-form">
                          Metode Tanam
                        </label>
                        <select id="planting_method_int" className="input-form" {...register("planting_method_int")}>
                          <option value="">- Pilih Metode Tanam -</option>
                          {dataPlanting!.map((item, key) => (
                            <option key={key} value={item.id_seq}>
                              {item.name_planting_var}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null
                  ) : (
                    <div className="w-full">
                      <label htmlFor="planting_method_int" className="label-form">
                        Metode Tanam
                      </label>
                      <select id="planting_method_int" className="input-form" {...register("planting_method_int")} onChange={(e) => setPlantingMethod(Number(e.target.value))}>
                        <option value="">- Pilih Metode Tanam -</option>
                        {dataPlanting!.map((item, key) => (
                          <option key={key} value={item.id_seq}>
                            {item.name_planting_var}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="w-full">
                    <label htmlFor="source_funding_var" className="label-form">
                      Sumber Dana
                    </label>
                    <select id="source_funding_var" className="input-form" {...register("source_funding_var", { required: "This field is required" })}>
                      <option value="">- Pilih Sumber Dana -</option>
                      {SOURCE_FUNDING_LIST!.map((item, key) => (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <ErrorField errors={errors} name="source_funding_var" />
                  </div>
                  <div className="w-full">
                    <label htmlFor="file_template_txt" className="label-form">
                      File Template Task
                    </label>
                    <select id="file_template_txt" className="input-form" {...register("file_template_txt", { required: "This field is required" })}>
                      <option value="">- Pilih Template -</option>
                      {dataTemplate!.map((item, key) => (
                        <option key={key} value={getFilename(item.file_name_txt)}>
                          {getFilename(item.file_name_txt)}
                        </option>
                      ))}
                    </select>
                    <ErrorField errors={errors} name="file_template_txt" />
                  </div>
                </div>
              </div>

              {/* <!-- Modal footer --> */}
              <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                <button type="submit" className="btn-primary">
                  Simpan
                </button>
                <button type="reset" className="btn-secondary px-7" onClick={onCancel}>
                  Batal
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ModalFormCultivation;
