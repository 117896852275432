import { useCallback, useContext, useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"

import { createNewProject, deleteProject, getAllProject, IProjectManagement, updateProject } from "../../api/project-api"

import TableUser from "../../components/tables/Table"
import { ButtonAdd } from "../../components/button/CustomButton"
import { DropdownLand } from "../../components/dropdown/SimpleDropdown"

import Loader from "../../components/modal/Loader";
import ModalFormProject from "../../components/modal/ModalFormProject"

import React from 'react';
import { toast } from "react-toastify"
import { ActionType } from "../../reduxs/action/actions"
import SearchField from "../../components/SearchField"
import { useLocation } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalProvider"
import { IRecentPage } from "../../context/ContextInterface"
import { getPayloadRecentActivity } from "../../helpers/helper"


const TITLE_BAR = 'Project Management / Project'

type Props = {
    user?: any,
};

const ProjectManagement: React.FC<Props> = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [originData, setOriginData] = useState<IProjectManagement[]|[]>([])
    const [filterData, setFilterData] = useState<IProjectManagement[]|[]>([])
    const [selectedData, setSelectedData] = useState<IProjectManagement|null>(null)

    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { dispatchCalPage, dispatchCalActivity } = useContext(GlobalContext)

    useEffect(() => {
        const payload: IRecentPage = {
            id: new Date().getTime(),
            title: TITLE_BAR,
            path: pathname,
            url: window.location.href,
        };
        
        dispatchCalPage({type: 'push', payload})
        dispatch({type: ActionType.SET_TITLE_BAR, value: TITLE_BAR})
    }, [dispatch, dispatchCalPage, pathname])


    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await getAllProject()

        showLoader(false)
        console.log("Project Management :", res)
        if(res.data){
            if(res.data.code === 0){
                setOriginData(res.data.data)
                setFilterData(res.data.data)
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData, dispatch])


    const handleEditData =  (selectedData: IProjectManagement) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (data: IProjectManagement) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            data.created_by_var = user.fullname_var
            res = await createNewProject(data)

            var payload = getPayloadRecentActivity(1, 'Project', data.project_name_var, user.fullname_var, pathname)
        }else{
            data.updated_by_var = user.fullname_var
            res = await updateProject(selectedData?.id_seq!, data)

            payload = getPayloadRecentActivity(2, 'Project', data.project_name_var, user.fullname_var, pathname)
        }

        console.log('Create/Update ProManage :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success(res.data.message)

                dispatchCalActivity({type: 'push', payload})
                
                fetchData()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const handleDeleteData = async (data: IProjectManagement) => {
        showLoader(true)
        const res = await deleteProject(data.id_seq!)

        console.log("DELETE ProManage :", res)
        if(res.data){
            if(res.data.code === 0){
                toast.success(res.data.message)
                
                const payload = getPayloadRecentActivity(3, 'Project', data.project_name_var, user.fullname_var, pathname)
                dispatchCalActivity({type: 'push', payload})

                fetchData()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            toast.error(res.status + ' ' + res.message || 'Connection timeout')
        }
    }

    const resetForm = () => {
        setSelectedData(null)
        setIsUpdate(false)
        showModalForm(false)
        showLoader(false)
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        const newData = [...originData]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.project_name_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.project_code_var.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(originData)
        }
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            accessor: 'no',
            Cell: ({ row }: any) =>  <div className='text-left pl-4'>{row.index + 1}.</div>,
        },
        {
            Header: 'Kode Projek',
            accessor: 'project_code_var',
        },
        {
            Header: 'Nama Projek',
            accessor: 'project_name_var'
        },
        // {
        //     Header: 'Admin',
        //     accessor: 'fullname_var',
        //     Cell: ({row, value}: any) => {
        //         const data = row.original
        //         return value || data.user_id_int
        //     }
        // },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}: any) => (
                parseInt(value)===1? 
                <span className='bg-green-100 text-green-800 px-2 py-1 rounded-xl font-medium'>Active</span>
                :
                <span className='bg-red-100 text-red-800 px-2 py-1 rounded-xl font-medium'>Inactive</span>
            )
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEditData(data)} onDelete={()=>handleDeleteData(data)} />
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-5 md:p-6 2xl:p-10 mb-auto'>
            <div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:p-6'>
                <div className='mb-5 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0'>
                    <h1 className='font-bold uppercase text-xl'>List Project</h1>
                    <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-2.5 2xl:space-x-3 space-y-2 md:space-y-0'>
                        <SearchField onChange={handleSearch} placeholder='Cari data project...' />
                        <ButtonAdd onClick={() => showModalForm(true)} />
                    </div>
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalFormProject data={selectedData!} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, null) (ProjectManagement)