import { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../ErrorField";
import dayjs from "dayjs";
import { IProductList } from "../../../api/inventory-api";
import Loader from "../Loader";
import { useDropdownProductType } from "../../../hooks/useDropdownInventory";

type Props = {
    data: IProductList,
    onCancel: (e: React.MouseEvent) => any
    onSubmit: (data: IProductList) => void
};

const ModalFormProduct: React.FC<Props> = ({ data, onCancel, onSubmit }) => {
    const { productTypeList, loadingProductType } = useDropdownProductType()
    
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj) => {

        Object.keys(obj).map(key => {
            if (key === 'start_planting_dtm') {
                return setValue(key, dayjs(obj[key]).format('YYYY-MM-DD'))
            } else {
                return setValue(key, obj[key], { shouldValidate: true })
            }
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])

    const onSubmitForm: SubmitHandler<IProductList> = ({ id_product_var, product_name_var, product_type_int }) => {

        const payload = {
            id_product_var, product_name_var, product_type_int
        }

        onSubmit(payload)
    }

    return (
        <>
        {!loadingProductType?
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onCancel}></div>

                <div className="modal-form-content" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-300 mb-5">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="fa-brands fa-pagelines flex justify-center items-center"></i>
                            <h3>{data ? 'Ubah Produk' : 'Tambah Produk'}</h3>
                        </div>
                        <button type="button" className="text-slate-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-slate-600 hover:text-white"
                            onClick={onCancel}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className='grid gap-5 grid-cols-1 md:grid-cols-2'>
                            <div className="w-full">
                                <label htmlFor="id_product_var" className="label-form">ID Produk</label>
                                <input id='id_product_var' type='text' className='input-form' placeholder="ID Produk"
                                    {...register('id_product_var', { required: 'This field is required.' })}
                                />
                                <ErrorField errors={errors} name='id_product_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="product_name_var" className="label-form">Nama Produk</label>
                                <input id='product_name_var' type='text' className='input-form' placeholder="Nama Produk"
                                    {...register('product_name_var', { required: 'This field is required.' })}
                                />
                                <ErrorField errors={errors} name='product_name_var' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="product_type_int" className="label-form">Tipe Produk</label>
                                <select id="product_type_int" className="input-form" {...register('product_type_int', { required: 'This field is required' })}>
                                    <option value=''>- Pilih tipe produk -</option>
                                    {productTypeList.map((item, key) =>
                                        <option value={item.id_seq} key={key}>{item.product_name_type_var}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='product_type_int' />
                            </div>
                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-10 pt-4 space-x-4 rounded-b border-t border-slate-300 -mx-10 px-10">
                            <button type='submit' className="btn-primary">Simpan</button>
                            <button type='reset' className="btn-secondary px-7" onClick={onCancel}>Batal</button>
                        </div>

                    </form>

                </div>
            </div>
            :
            <Loader />
        }
        </>
    )
}


export default ModalFormProduct